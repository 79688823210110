<template>
    <div>
      <van-nav-bar title="个人基础信息" left-text="返回" @click-left="$router.back()" left-arrow :fixed="true" placeholder/>
      <van-cell title="姓名" :value="userName" size="large" />
      <van-cell title="手机号码" :value="userMobile" size="large" />
    </div>
</template>
<script>
export default {
    name: 'UserInfo',
    data(){
        return {
          userName: this.$route.query.userName,
          userMobile: this.$route.query.userMobile,
        }
    },
}
</script>

<style lang="less" scoped>
   
</style>

