const state = {
    sellerState: 0,
    buyerState: 0,
    consumerState: 0
}

const getters = {
    sellerState: (state, getters) => {
        return state.sellerState
    },
    buyerState: (state, getters) => {
        return state.buyerState
    },
    consumerState: (state, getters) => {
        return state.consumerState
    },
}

// mutations
const mutations = {
    SET_SELLER_STATE(state, status) {
        state.sellerState = status
        console.log('SET_SELLER_STATE')
    },
    SET_BUYER_STATE(state, status) {
        state.buyerState = status
        console.log('SET_BUYER_STATE')
    },
    SET_CONSUMER_STATE(state, status) {
        state.consumerState = status
        console.log('SET_CONSUMER_STATE')
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}