<template>
<div>
    <van-nav-bar title="我的分享订单" left-text="返回" @click-left="$router.back()" left-arrow :fixed="true" placeholder/>
    <van-tabs v-model="active" animated @change="tabChange">
        <van-tab>
            <div slot="title" class="flex-row flex-center just-center">
                <span class="tab-title">备货中</span>
                <van-tag plain type="danger" v-if="infoDelivery.pagination">{{infoDelivery.pagination ? infoDelivery.pagination.total: 0}}</van-tag>
            </div>
            <van-list v-model="loadingpaid" :finished="finishedDelivery" finished-text="没有更多了" @load="pageLoad">
                <div class="c-cell" @click="orderInfo(item.orderId)" v-for="(item,idx) in infoDelivery.items" :index="idx" :key="idx">
                    <div class="company flex-row space-between">
                        <div class="flex-center">
                            <van-icon class="icon" name="shop-o" />
                            <span>{{item.operatorName}}</span>
                        </div>
                        <div>{{item.status}}</div>
                    </div>
                    <div class="info flex-row">
                        <img v-if="item.img" :src="item.img" />
                        <div class="flex-column space-between">
                            <span>{{item.productName}}</span>
                            <span :class="'price-shared'">￥{{stringMoney(item.unitPrice)}}</span>
                        </div>
                    </div>
                    <div class="statistics">
                        共 {{ item.numbers || 0 }} 件商品 实付款:
                        <span class="price">{{stringMoney(item.totalAmount)}}</span>
                    </div>
                </div>
            </van-list>

        </van-tab>
        <van-tab>
            <div slot="title" class="flex-row flex-center just-center">
                <span class="tab-title">配送中</span>
                <van-tag plain type="danger" v-if="infoDelivering.pagination">{{infoDelivering.pagination ? infoDelivering.pagination.total: 0}}</van-tag>
            </div>
            <van-list v-model="loadingDelivery" :finished="finishedDelivering" finished-text="没有更多了" @load="pageLoad">
                <div class="c-cell" @click="orderInfo(item.orderId)" v-for="(item,idx) in infoDelivering.items" :index="idx" :key="idx">
                    <div class="company flex-row space-between">
                        <div class="flex-center">
                            <van-icon class="icon" name="shop-o" />
                            <span>{{item.operatorName}}</span>
                        </div>
                        <div>{{item.status}}</div>
                    </div>
                    <div class="info flex-row">
                        <img v-if="item.img" :src="item.img" />
                        <div class="flex-column space-between">
                            <span>{{item.productName}}</span>
                            <span :class="'price-shared'">￥{{stringMoney(item.unitPrice)}}</span>
                        </div>
                    </div>
                    <div class="statistics">
                        共 {{ item.numbers || 0 }} 件商品 实付款:
                        <span class="price">{{stringMoney(item.totalAmount)}}</span>
                    </div>
                </div>
            </van-list>
        </van-tab>
        <van-tab>
            <div slot="title" class="flex-row flex-center just-center">
                <span class="tab-title">已完成</span>
            </div>
            <van-list v-model="loadingCompleted" :finished="finishedCompleted" finished-text="没有更多了" @load="pageLoad">
                <div class="c-cell" @click="orderInfo(item.orderId)" v-for="(item,idx) in infoCompleted.items" :index="idx" :key="idx">
                    <div class="company flex-row space-between">
                        <div class="flex-center">
                            <van-icon class="icon" name="shop-o" />
                            <span>{{item.operatorName}}</span>
                        </div>
                        <div>{{item.status}}</div>
                    </div>
                    <div class="info flex-row">
                        <img v-if="item.img" :src="item.img" />
                        <div class="flex-column space-between">
                            <span>{{item.productName}}</span>
                            <span :class="'price-shared'">￥{{stringMoney(item.unitPrice)}}</span>
                        </div>
                    </div>
                    <div class="statistics">
                        共 {{ item.numbers || 0 }} 件商品 实付款:
                        <span class="price">{{stringMoney(item.totalAmount)}}</span>
                    </div>
                </div>
            </van-list>
        </van-tab>
    </van-tabs>

</div>
</template>

<script>
import baseMixin from '../mixins/base.js'
import { reduce } from "lodash"

export default {
    name: 'OrderList',
    mixins: [baseMixin],
    components: {},
    data() {
        return {
            companyId: this.$route.query.companyId, 
            memberId: this.$route.query.memberId, 
            active: 0,
            curStep: 'paid',
            // 备货中 paid
            // 配送中 delivery
            // 已完成 receive  

            tradeRole: 'seller',
            // url中companyId在交易中的类型   采购 buyer  供应 seller  tradeRole为seller、OrderType为consumer时是云店订单
            // 待确认价格 ChangePrice
            loadingChangePrice: false,
            pageChangePrice: 1,
            finishedChangePrice: false,
            infoChangePrice: [],
            // 待备货 delivery
            loadingpaid: false,
            pagePaid: 1,
            finishedDelivery: false,
            infoDelivery: [],
            // 未支付 waitingPay
            loadingWaitingPay: false,
            pageWaitingPay: 1,
            finishedWaitingPay: false,
            infoWaitingPay: [],
            // 配送中 delivering
            loadingDelivery: false,
            pageDelivery: 1,
            finishedDelivering: false,
            infoDelivering: [],
            // 确认收货 completed
            loadingCompleted: false,
            pageCompleted: 1,
            finishedCompleted: false,
            infoCompleted: [],
            firstLoad: true,
        }
    },
    mounted() {
      this.requestData()
    },
    methods: {
        reduceCount(items) {
            return reduce(items, function(rst, item) {
                return rst + item.num
            }, 0);
        },
        countPrice(items) {
            const price = items.reduce((pre, curr) => {
                return pre + (curr.price * curr.num);
            }, 0);
            return this.stringMoney(price);
        },
        orderInfo(id) {
            this.$router.push('/order/info?id=' + id + '&is_consumer=1&readonly=1')
        },
        tabChange(e) {
            console.log(e, this.active)
            console.log(e, this.active)
            this.$store.commit("tab/SET_CONSUMER_STATE", e);
        },
        async pageLoad() {
            console.log('pageload',this.active)
            if (this.firstLoad) {
                return  this.firstLoad = false
            }
            
            switch (this.active) {
                case 0:
                    this.curStep = 'paid'
                    this.pagePaid++
                    this.loadingpaid = true
                    break;
                case 1:
                    this.curStep = 'delivery'
                    this.pageDelivery++
                    this.loadingDelivery = true
                    break;
                case 2:
                    this.curStep = 'receive'
                    this.pageCompleted++
                    this.loadingCompleted = true
                    break;
            }
            await this.requestList(this.curStep, true)
        },
        async requestList(step, loadmore) {
            let loading = null
            if (loadmore) {
                loading = this.$toast.loading({
                    mask: true,
                    message: '加载中...'
                });
            }
            switch (step) {
                case 'paid':
                    this.page = this.pagePaid
                    break;
                case 'delivery':
                    this.page = this.pageDelivery
                    break;
                case 'receive':
                    this.page = this.pageCompleted
                    break;
            }
            let curStep = step
             const orderRes = await this.$API.getOrderListOfShared(this.companyId, this.memberId, curStep, {
                page: this.page,
                pageSize: this.pageSize,
            })
            // let orderRes = await this.$API.getOrderList(this.companyId, {
            //     page: this.page,
            //     pageSize: this.pageSize,
            // }, {
            //     tradeRole: 'seller',
            //     curStep: 'completed'
            // })
            console.log('orderRes',orderRes)
            if (orderRes.status == 200) {
                let product = orderRes.data
                switch (step) {
                    case 'paid':
                        if (loadmore && this.infoDelivery.items instanceof Array) {
                            this.infoDelivery.items = this.infoDelivery.items.concat(product.items)
                        } else {
                            this.infoDelivery = product
                        }
                        this.loadingpaid = false
                        this.finishedDelivery = !orderRes.data.pagination ? true : ((orderRes.data.pagination.total / (this.pagePaid * this.pageSize)) <= 1)
                        break;
                    case 'delivery':
                        if (loadmore && this.infoDelivering.items instanceof Array) {
                            this.infoDelivering.items = this.infoDelivering.items.concat(product.items)
                        } else {
                            this.infoDelivering = product
                        }
                        this.loadingDelivery = false
                        this.finishedDelivering = !orderRes.data.pagination ? true : ((orderRes.data.pagination.total / (this.pageDelivery * this.pageSize)) <= 1)
                        break;
                    case 'receive':
                        if (loadmore && this.infoCompleted.items instanceof Array) {
                            this.infoCompleted.items = this.infoCompleted.items.concat(product.items)
                        } else {
                            this.infoCompleted = product
                        }
                        this.loadingCompleted = false
                        this.finishedCompleted = !orderRes.data.pagination ? true : ((orderRes.data.pagination.total / (this.pageCompleted * this.pageSize)) <= 1)
                        break;
                }
            }
            if (loadmore) {
                loading.clear()
            }
        },
        async requestData() {
            const loading = this.$toast.loading({
                mask: true,
                message: '加载中...'
            });
            await this.requestList('paid')
            await this.requestList('delivery')
            await this.requestList('receive')
            loading.clear()
        },
    }
}
</script>

<style lang="less" scoped>
.c-cell {
    padding: 10px 0;
    box-sizing: border-box;
    margin-bottom: 5px;
    background: #fff;
    margin-top: 5px;
}

.company {
    padding: 0 16px;

    .icon {
        margin-right: 5px;
    }
}

.info {
    margin-top: 10px;
    background: #fafafa;
    padding: 10px;
    margin-bottom: 10px;

    img {
        width: 5rem;
        height: 5rem;
        margin-right: 20px;
        border: 1px solid #ececec;
    }

    span {
        font-size: 16px;
    }
}

.statistics {
    padding: 0 16px;
    text-align: right;

    .price {
        font-size: 16px;
        margin-left: 10px;
    }
}

.tab-title {
    margin-right: 10px;
}
</style>
