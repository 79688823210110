import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Layout from './components/layout.vue'
import Detail from './views/detail.vue'
import Confirm from './views/confirm.vue'
import Address from './views/address.vue'
import AddAddress from './views/add-address.vue'
import OrderListBuyer from './views/order-buyer.vue'
import OrderListSeller from './views/order-seller.vue'
import OrderListConsumer from './views/order-consumer.vue'
import Blank from './views/blank.vue'
import Login from './views/login.vue'
import OrderInfo from './views/order-info.vue'
import Success from './views/success.vue'
import ShoppingCar from './views/shopping-car.vue'
// prepare-order
import PrePareOrder from './views/prepare-order.vue'
import Admin from './views/admin.vue'
import ModifyPassword from './views/modify-password.vue'
import UserInfo from './views/user-info.vue'
import MapLocation from './views/map-location.vue'
import SharedOrders from './views/shared-orders.vue'

Vue.use(Router)

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'Layout',
			component: Layout,
			children: [
				{
					path: '/',
					name: 'Home',
					component: Home,
				},
				{
					path: '/order-list/buyer',
					name: 'OrderBuyer',
					component: OrderListBuyer,
				},
				{
					path: '/order-list/seller',
					name: 'OrderSeller',
					component: OrderListSeller,
				},
				{
					path: '/order-list/consumer',
					name: 'OrderConsumer',
					component: OrderListConsumer,
				},
				{
					path: '/blank',
					name: 'Blank',
					component: Blank,
				},
				{
					path: '/admin',
					name: 'Admin',
					component: Admin,
				},
			]
		},
		{
			path: '/login',
			name: 'Login',
			component: Login,
		},
		{
			path: '/detail',
			name: 'Detail',
			component: Detail,
		},
		{
			path: '/confirm',
			name: 'Confirm',
			component: Confirm,
		},
		{
			path: '/confirm/muilti',
			name: 'ConfirmMuilti',
			component: Confirm,
		},
		{
			path: '/address',
			name: 'Address',
			component: Address,
		},
		{
			path: '/address/add',
			name: 'AddAddress',
			component: AddAddress,
		},
		{
			path: '/address/edit',
			name: 'EditAddress',
			component: AddAddress,
		},
		{
			path: '/order/info',
			name: 'OrderInfo',
			component: OrderInfo,
		},
		{
			path: '/prepare/order',
			name: 'PrePareOrder',
			component: PrePareOrder,
		},
		{
			path: '/deliver/order',
			name: 'DeliverOrder',
			component: PrePareOrder,
		},
		{
			path: '/success',
			name: 'Success',
			component: Success,
		},
		{
			path: '/shopping-car',
			name: 'ShoppingCar',
			component: ShoppingCar,
		},
		{
			path: '/admin/modify-password',
			name: 'ModifyPassword',
			component: ModifyPassword,
		},
		{
			path: '/admin/user-info',
			name: 'UserInfo',
			component: UserInfo,
		},
		{
			path: '/admin/map-location',
			name: 'MapLocation',
			component: MapLocation,
		},
		{
			path: '/admin/shared-orders',
			name: 'SharedOrders',
			component: SharedOrders,
		},
	]
})
