<template>
<div>
    <!-- right-text="新增收货地址" -->
    <!-- @click-right="$router.push('/address/add')" -->
    <van-nav-bar title="收货地址" left-text="返回" @click-left="$router.back()"  left-arrow fixed placeholder >
    </van-nav-bar>
    <!-- <van-cell v-for="(item,idx) in [1,2,3,4,5,6,7]" :index="idx" :key="idx">
        <template slot="title">
            <van-checkbox v-model="checked">
                <div class="flex-column">
                    <div class="flex-row">
                        <span class="addr-name">张阿三</span>
                        <span class="addr-phone">13402840115</span>
                    </div>
                    <span>成都市锦江区高攀路万科汇智中心 2302</span>
                </div>
            </van-checkbox>
        </template>
    </van-cell> -->
    <van-address-list v-model="chosenAddressId" :list="list" @select="onSelect" @add="onAdd" @edit="onEdit" />
</div>
</template>

<script>
import baseMixin from '../mixins/base.js'
import AreaList from '../common/area.js'
export default {
    name: 'Address',
    mixins: [baseMixin],
    components: {},
    data() {
        return {
            checked: false,
            list: [],
            chosenAddressId: -1,
            shippingaddressesList: [],
        }
    },
    mounted(){
        this.requestData()
    },
    methods:{
        onAdd(){
            this.$router.push('/address/add')
        },
        onEdit(address){
            this.$router.push(`/address/edit?id=${address.id}`)
        },
        onSelect(e){
            console.log(e)
            this.$store.commit("address/SET_CURRENT_ADDRESS", e);
            this.$router.back()
            // this.setDefaultAddress(e)
        },
        async setDefaultAddress(item){
            const loading = this.$toast.loading({
                mask: true,
                message: '加载中...'
            });
            item.isDefault = 1
            let res = await this.$API.editShippingaddresses(this.companyId, item.id, {
                isDefault: 1
            })
            if (res.status == 200) {
                return await this.requestData()
            }
            loading.clear()
        },
        async getShippingaddresses() {
            let res = await this.$API.getShippingaddresses(this.companyId)
            this.shippingaddressesList = res.data.items
            for (let i = 0; i < this.shippingaddressesList.length; i++) {
                const address = this.shippingaddressesList[i];
                if (address.isDefault == '1') {
                    this.addressChecke = address.id
                    this.chosenAddressId = address.id
                }
            }
            this.list = []
            // let currentAddress = this.$getters["address/currentAddress"]
            // if (currentAddress.name) {
            //     this.list.push({
            //         id: -2,
            //         address: this.detailAddress(currentAddress),
            //         name: currentAddress.name,
            //         tel: currentAddress.mobile,
            //         ...currentAddress
            //     })
            // }
            for (let i = 0; i < this.shippingaddressesList.length; i++) {
                const item = this.shippingaddressesList[i];
                this.list.push({
                    id: item.id,
                    address: this.detailAddress(item),
                    name: item.name,
                    tel: item.mobile,
                    ...item
                })
            }
        },
        async requestData(){
            const loading = this.$toast.loading({
                mask: true,
                message: '加载中...'
            });
            await this.getShippingaddresses()
            loading.clear()
        },

        detailAddress(defaultAddress) {
            if (!defaultAddress.districtId) return ''
            const pid = defaultAddress.districtId.substring(0, 2) + '0000'
            const cid = defaultAddress.districtId.substring(0, 4) + '00'
            const zid = defaultAddress.districtId
            return `${AreaList.province_list[pid]}/${AreaList.city_list[cid]}/${AreaList.county_list[zid]}/${defaultAddress.address}`
        }
    }
}
</script>

<style lang="less" scoped>
.addr-name {
    font-size: 16px;
    margin-right: 10px;
}
</style>
