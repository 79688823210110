<template>
    <div>
        <van-nav-bar title="商品详情" left-text="返回" @click-left="customBack" left-arrow fixed placeholder />
        <div class="product" v-if="goodsInfo.name">
            <div class="cover">
                <img :src="imgs[0]" />
            </div>
            <div class="cell">
                <van-cell :title="goodsInfo.name" />
            </div>
            <div class="cell" v-if="type == 'shared'">
                <van-cell
                    title="收货地"
                    icon="location"
                    is-link
                    @click="showAreaSelect = true"
                    :value="`${(id2name(destination.provinceId) || '省')} ${(id2name(destination.cityId) || '市')} ${(id2name(destination.districtId)|| '区')}`"
                    size="mini"
                />
            </div>
            <div class="cell" v-if="type == 'supply'">
                <van-cell>
                    <span class="price-supply">零售价: {{stringMoney(goodsInfo.officialPrice)}}</span>
                </van-cell>
            </div>
            <div class="m-cell">
                <div v-if="goodsInfo.shop" class="company flex-center">
                    <van-icon class="icon" name="shop-o" />
                    <span>{{goodsInfo.shop.name}}</span>
                </div>
                <div class="price">
                    <span>
                        {{type == 'shared' ? (goodsInfo.price?'￥' + stringMoney(goodsInfo.price):'无货') : ''}}
                    </span>
                </div>
            </div>
            <div class="cell">
                <van-cell>
                    <template slot="title">
                        <div class="flex-row space-between">
                            <span class="custom-title">采购数量: </span>
                            <van-stepper v-model="count" />
                        </div>
                    </template>
                </van-cell>
            </div>
        </div>
        <div class="bottom flex-row">
            <div
                class="add-cart"
                @click="canOrder && addCart(goodsInfo)"
            >
                添加至购物车
            </div>
            <div
                class="pay-button"
                @click="canOrder && confirm(goodsInfo.id)"
                :style="canOrder ? '' : 'background-color: gray'"
            >
                立即下单
            </div>
        </div>

        <div class="areaSelect">
            <transition name="van-fade">
                <van-overlay
                    v-show="showAreaSelect"
                    :show="showAreaSelect"
                    @click="showAreaSelect = false"
                />
            </transition>
            <transition name="van-slide-up">
                <van-area
                    @cancel="showAreaSelect = false"
                    :value="''+destination.districtId"
                    @confirm="confirmArea"
                    v-show="showAreaSelect"
                    class="area"
                    :area-list="areaList"
                />
            </transition>
        </div>
    </div>
</template>

<script>
    import baseMixin from '../mixins/base.js'
    import AreaList from '../common/area.js'
    export default {
        name: 'Detail',
        mixins: [baseMixin],
        components: {},
        data() {
            return {
                count: 1,
                goodsInfo: {},
                imgs: [],
                buyCompanyId: -1,
                productId: -1,
                destination: {
                    cityId: 510000,
                    provinceId: 510100,
                    districtId: 510104
                },
                type: '',
                showAreaSelect: false,
                destination: {
                    cityId: 510000,
                    provinceId: 510100,
                    districtId: 510104
                },
                areaList: {},
            }
        },
        computed: {
            canOrder() {
                if (this.type == 'shared') return !!this.goodsInfo.price
                else return !!this.goodsInfo.officialPrice
            }
        },
        mounted() {
            this.destination = {
                cityId: this.$route.query.cityId,
                provinceId: this.$route.query.provinceId,
                districtId: this.$route.query.districtId,
            };
            this.productId = this.$route.query.id;
            this.buyCompanyId = this.$route.query.companyid;
            this.type = this.$route.query.type;
            this.requestData();
            this.areaList = AreaList;
        },
        methods: {
            customBack(){
                if (window.history.length <=2) {
                    this.$router.push('/')
                } else {
                    this.$router.back()
                }
            },
            async addCart(product) {
                await this.$store.dispatch('cart/getCart');

                const isSupply = this.type === 'supply';

                if (isSupply) {
                    // 获取供应商的区域地质
                    let res = await this.$API.getCompanyArea(product.shop.id);
                    let dest = res.data.items[0]
                    this.destination = {
                        cityId: dest.cityId,
                        provinceId: dest.provinceId,
                        districtId: dest.districtId
                    }
                }
                // 组装需要存储的数据
                const data = {
                    id: isSupply ? `${product.shop.id}-${product.id}` : `${this.destination.districtId}-${product.id}`,
                    sellType: isSupply ? 'supply' : 'share',
                    items: {
                        productId: product.id,
                        name: product.name,
                        images: product.images,
                        count: this.count,
                        price: isSupply ? product.officialPrice : product.price,
                    }
                };

                if (isSupply) {
                    data.sellerId = product.shop.id;
                    data.items.shopName = product.shop.name;
                } else {
                    data.area = this.destination;
                }

                this.$store.dispatch('cart/addCart', data).then(async () => {
                    await this.$store.dispatch('cart/saveCart');
                    this.$toast.success('添加购物车成功!');
                });
            },
            confirm(id) {
                this.$router.push({
                    path: '/confirm',
                    query: {
                        id,
                        num: this.count,
                        companyid: this.buyCompanyId,
                        type: this.type,
                        cityId: this.destination.cityId,
                        provinceId: this.destination.provinceId,
                        districtId: this.destination.districtId,
                    },
                });
            },
            // 获取用户默认区域
            async getCompanyArea() {
                let res = await api.getCompanyArea(this.companyId)
                if (res.status == 200) {
                    this.destination = res.data.items[0]
                }
                await this.getCatalogsWithArea()
            },

            async getCatalogsWithArea() {
                const loading = this.$toast.loading({
                    mask: true,
                    message: '加载中...'
                });
                let cataLogres = await this.$API.getCatalogs(this.companyId, {
                    page: 1,
                    pageSize: 999
                }, {
                        provinceId: this.destination.provinceId,
                        cityId: this.destination.cityId,
                        districtId: this.destination.districtId,
                        productId: this.productId,
                        type: 'shared'
                    })
                if (cataLogres.data.items.length) {
                    this.goodsInfo.shop = cataLogres.data.items[0].shop
                    this.goodsInfo.price = cataLogres.data.items[0].price
                    this.buyCompanyId = this.goodsInfo.shop.id
                } else {
                    this.goodsInfo.price = null
                    this.goodsInfo.shop = this.goodsInfo.shop || {}
                    this.goodsInfo.shop.name = null
                }
                loading.clear()
            },
            async requestData() {
                let res
                if (this.type === 'shared') {
                    res = await this.$API.getSharedProductInfo(this.productId, this.type, this.destination)
                } else {
                    res = await this.$API.getBuyerProductInfo(this.buyCompanyId, this.productId, this.type)
                }
                this.goodsInfo = res.data;
                this.currentGoods = res.data;
                this.imgs = res.data.images;
            },
            confirmArea(e) {
                this.destination = {
                    provinceId: e[0].code,
                    cityId: e[1].code,
                    districtId: e[2].code,
                }
                this.showAreaSelect = false;
                this.requestData();
            },
        }
    }
</script>

<style lang="less" scoped>
.cover {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    background: #fff;

    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    margin-bottom: 5px;
}

.cell {
    margin-bottom: 5px;
}

.price {
    margin-top: 5px;
    color: red;
    font-size: 20px;
    font-weight: bold;
}

.product {
    padding-bottom: 100px;
}

.bottom {
    height: 50px;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    background: #fff;
    border-top: 1px solid #ececec;
    justify-content: flex-end;
    .add-cart {
    height: 50px;
    width: 150px;
    color: #fff;
    font-size: 16px;
    line-height: 50px;
    text-align: center;
    background: rgb(73, 73, 73);
    border-right: 1px solid #fff;
    }

    .pay-button {
    height: 50px;
    width: 150px;
    color: #fff;
    font-size: 16px;
    line-height: 50px;
    text-align: center;
    // position: absolute;
    right: 0;
    bottom: 0;
    background: #ee6f2d;
    }
}

.company {
    font-size: 12px;

    .icon {
    margin-right: 5px;
    }
}

.areaSelect {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;

    .area {
    z-index: 2001;
    }
}
</style>
