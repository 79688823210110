import axios from '../common/axios'
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
    async getDealers(companyId, pagion = { page: 1, pageSize: 20 }, search = { searchKey: '', provinceId: '', cityId: '', districtId: '' }) {
        let res = await axios.get(`/business/api/companies/${companyId}/dealers`, {
            params: {
                page: pagion.page,
                pageSize: pagion.pageSize,
                searchKey: search.searchKey,
                provinceId: search.provinceId,
                cityId: search.cityId,
                districtId: search.districtId,
            }
        })
        return res
    },

    async createDealer(companyId, company) {
        let res = await axios({
            url: `/business/api/companies/${companyId}/dealers`,
            method: 'POST',
            data: {
                company
            }
        })
        return res
    },
    // /business/api/companies/{companyId}/dealers/{dealerId}
    async editDealerInfo(companyId,dealerId,val){
        let res = await axios({
            url: `/business/api/companies/${companyId}/dealers/${dealerId}`,
            method: 'PATCH',
            data:{
                ...val
            }
        })
        return res
    },
    // 
    async setSupplyProduct(supplierId, dealerId, seriesIds) {
        let res = await axios({
            url: `/business/api/suppliers/${supplierId}/dealers/${dealerId}/supplyProduct`,
            method: 'POST',
            data: {
                seriesIds
            }
        })
        return res
    },
    // /business/api/suppliers/{supplierId}/dealers/{dealerId}/supplyProduct
    async getSupplyProduct(supplierId,dealerId){
        let res  = await axios.get(`/business/api/suppliers/${supplierId}/dealers/${dealerId}/supplyProduct?type=tree`)
        return res
    },
    // /business/api/companies/{companyId}/shippingaddresses
    async addShippingaddresses(companyId, value, isDefault) {
        let res = await axios({
            url: `/business/api/companies/${companyId}/shippingaddresses`,
            method: 'POST',
            data: {
                ...value,
                isDefault
            }
        })
        return res
    },
    // /business/api/companies/{companyId}/shippingaddresses
    async getShippingaddresses(companyId){
        let res = await axios.get(`/business/api/companies/${companyId}/shippingaddresses`)
        return res
    },
    // /business/api/companies/{companyId}/shippingaddresses/{addressId}
    async editShippingaddresses(companyId, addressId, value){
        let res = await axios({
            url: `/business/api/companies/${companyId}/shippingaddresses/${addressId}`,
            method: 'PUT',
            data: {
                ...value,
            }
        })
        return res
    },
    // /business/api/companies/{companyId}/dealers/{dealerId}
    async getDealerInfo(companyId,dealerId){
        let res = await axios.get(`/business/api/companies/${companyId}/dealers/${dealerId}`)
        return res
    },
}