<template>
  <div>
    <van-nav-bar
      title="订单详情"
      left-text="返回"
      @click-left="customBack"
      left-arrow
      :fixed="true"
      placeholder
    ></van-nav-bar>
    <div v-if="orderInfo.items">
      <div v-for="(item, idx) of orderInfo.items" :key="idx">
        <div class="c-cell">
          <div class="company flex-row space-between">
            <div class="flex-center">
              <van-icon class="icon" name="shop-o" />
              <span>{{
                isFromSeller ? orderInfo.buyer.name : orderInfo.seller.name
              }}</span>
            </div>
            <div>{{ orderInfo.status.name }}</div>
          </div>
          <div class="info flex-row">
            <img :src="item.images[0]" />
            <div v-if="!readonly && showChangePrice" class="change-price">
              <span>{{ item.name }}</span>
              <div>
                <span>￥</span>
                <van-field
                  v-model="prices[idx].showPrice"
                  placeholder="请输入新价格"
                />
              </div>
            </div>
            <span v-else>{{ item.name }}</span>
          </div>
          <div class="statistics" v-if="item.price">
            共 {{ item.num }} 件商品 实付款:
            <span class="price"
              >￥{{
                stringMoney(item.num * (item.price || item.officialPrice))
              }}</span
            >

            <span
              v-if="orderInfo.allowActions.indexOf('delivery') !== -1"
              style="margin-left: 10px"
            >
                <van-button
                    v-if="!item.isPrepared"
                    v-show="!readonly"
                    @click="addPrepare(item)"
                    plain
                    size="small"
                >备货</van-button>
                <span style="color: #666" v-else >
                    已备货
                </span>
            </span>
          </div>
        </div>

        <div class="cell" v-if="item.price">
          <v-cell title="商品单价"
            >￥{{ stringMoney(item.price || item.officialPrice) }}</v-cell
          >
          <v-cell title="订单总价"
            ><span class="red-price"
              >￥{{
                stringMoney(item.num * (item.price || item.officialPrice))
              }}</span
            ></v-cell
          >
        </div>
      </div>
      <div class="operate c-cell" v-if="!readonly">
        <!-- isFormConsumer -->
        <div v-for="(item, idx) in orderInfo.allowActions" :key="idx">
          <van-button
            type="primary"
            plain
            size="small"
            v-if="item == 'receive'"
            @click="handleReceive"
            >{{isFormConsumer ? '货已送达' : '确认收货'}}</van-button
          >
          <van-button
            type="primary"
            plain
            size="small"
            v-if="item == 'delivery'"
            @click="handleFahuo"
            >发货</van-button
          >
          <van-button
            type="primary"
            plain
            size="small"
            v-if="item == 'delivering'"
            @click="handleGot"
            >货已送达</van-button
          >
          <van-button
            type="primary"
            plain
            size="small"
            v-if="item == 'deliveried'"
            @click="handleDeliveried"
            >货已送达</van-button
          >
          <van-button
            type="primary"
            plain
            size="small"
            v-if="item == 'accept'"
            @click="handleAccept"
            >确认</van-button
          >
          <van-button
            type="primary"
            plain
            size="small"
            v-if="item == 'changePrice'"
            @click="showPopChangePrice"
            > {{ showChangePrice ? '确认修改价格' : '价格确认'}}</van-button
          >
          <van-button
            :disabled="jy"
            type="warning"
            plain
            size="small"
            v-if="item == 'paying'"
            @click="handlePay"
            >支付</van-button
          >
          <van-button
            :disabled="jy"
            type="warning"
            plain
            size="small"
            v-if="item == 'payFail'"
            @click="again"
            >重新支付</van-button
          >
          <van-button
            type="primary"
            plain
            size="small"
            v-if="item == 'acceptOrder'"
            @click="acceptOrder"
          >
            接受订单
          </van-button>
          <van-button
            type="primary"
            plain
            size="small"
            v-if="item == 'canNotSupply'"
            @click="notAvailable"
          >
            无法供应
          </van-button>
          <van-button type="primary" plain size="small" v-if="item == 'skipPay'" @click="onSkipPay">跳过支付</van-button>
          <!-- <el-button v-if="inActions('changePrice')" @click="confirmChangePrice" type="primary" size="mini">确认修改价格</el-button> -->
        </div>
        <van-button
          type="primary"
          plain
          size="small"
          @click="handlePayProgress"
          v-if="orderInfo.status.code === 'paying'"
        >
          查看支付进度
        </van-button>
      </div>
      <div class="cell" v-if="hasPrepareInfo" @click="prepareInfo">
        <v-cell title="备货信息">
          <div class="flex-row space-between flex1">
            <span></span>
            <span class="m-link">备货信息</span>
          </div>
        </v-cell>
      </div>

      <div class="cell" v-if="orderInfo.receiver">
        <v-cell title="订单编号">{{ orderInfo.id }}</v-cell>
        <v-cell title="收货人">{{ orderInfo.receiver.recipient }}</v-cell>
        <v-cell title="联系方式">{{ orderInfo.receiver.phoneNumber }}</v-cell>
        <v-cell title="地址">{{
          `${id2name(orderInfo.receiver.provinceId) || '省'} ${id2name(
            orderInfo.receiver.cityId
          ) || '市'} ${id2name(orderInfo.receiver.districtId) || '区'}` +
            ' ' +
            orderInfo.receiver.address
        }}</v-cell>
      </div>
      <div v-if="isFromSeller">
        <div class="cell" v-if="orderInfo.buyer">
          <v-cell title="采购商">{{ orderInfo.buyer.name }}</v-cell>
          <v-cell title="联系人">{{ orderInfo.buyer.contactName }}</v-cell>
          <v-cell title="联系电话">{{ orderInfo.buyer.contactMobile }}</v-cell>
        </div>
      </div>
      <div v-if="!isFromSeller && !isFormConsumer">
        <div class="cell" v-if="orderInfo.seller">
          <v-cell title="供应商">{{ orderInfo.seller.name }}</v-cell>
          <v-cell title="联系人">{{ orderInfo.seller.contactName }}</v-cell>
          <v-cell title="联系电话">{{ orderInfo.seller.contactMobile }}</v-cell>
        </div>
      </div>
      <div v-if="isFormConsumer">
        <div class="cell" v-if="orderInfo.buyer">
          <v-cell title="微信名">{{ orderInfo.buyer.name }}</v-cell>
        </div>
      </div>
      <div >
        <div class="cell" v-if="orderInfo.remark">
          <v-cell title="备注">{{ orderInfo.remark }}</v-cell>
        </div>
      </div>
    </div>
    <!-- <div class="popwindow" v-if="prices[0]">
      <transition name="van-fade">
        <van-overlay
          v-show="showChangePrice"
          :show="showChangePrice"
          @click="showChangePrice = false"
        ></van-overlay>
      </transition>
      <transition name="van-slide-up">
        <div v-show="showChangePrice" class="change-price-window">
          <van-cell-group class="flex-column">
            <van-field
              v-model="prices[0].showPrice"
              placeholder="请输入新价格"
            />
          </van-cell-group>
          <van-button type="danger" @click="confirmPrice" class="confrim-btn"
            >确认价格，备货</van-button
          >
        </div>
      </transition>
    </div> -->
  </div>
</template>

<script>
import baseMixin from '../mixins/base.js';
import VCell from '../components/v-cell.vue';
import api from '../apis/index';
import { Dialog } from 'vant';
export default {
  name: 'OrderInfo',
  mixins: [baseMixin],
  components: {
    'v-cell': VCell
  },
  data() {
    return {
      readonly: this.$route.query.readonly,
      jy: false,
      orderId: -1,
      orderInfo: {},
      prices: [],
      isEditPrice: false,
      histories: [],
      showChangePrice: false,
      newPrice: 0,
      // 不确定
      showPopAddPrepare: false,
      orderCodes: [],
      isFromSeller: false,
      isFormConsumer: false
    };
  },
  computed: {
    hasPrepareInfo() {
        if (!this.histories || this.histories.length === 0) {
            return false;
        }

      // console.log(this.histories)
      for (const activity of this.histories) {
        if (activity.actionCode === 'prepareOrderGoods') return true;
      }
      return false;
    }
  },
  mounted() {
    this.orderId = this.$route.query.id;
    this.isFromSeller = this.$route.query.is_seller;
    this.isFormConsumer = this.$route.query.is_consumer;
    this.requestData();
  },
  methods: {
    async onSkipPay () {
        await api.skipPay(this.companyId, this.orderId);
        this.requestData();
    },
    // 接收订单 
    async acceptOrder() { 
      let res = await this.$API.acceptOrder(this.companyId, this.orderId);
      if (res.status == 200) {
        this.$toast.success('接受订单成功！');
        await this.requestData();
      }
    }, 
    // 无法供应 
    async notAvailable() {
      let res = await this.$API.notAvailable(this.companyId, this.orderId);
      if (res.status == 200) {
        this.$toast.success('操作成功！');
        this.$router.back();
      }
    }, 
    customBack() {
      if (window.history.length <= 2) {
        this.$router.push('/');
      } else {
        this.$router.back();
      }
    },
    async again() {
      this.jy = true;
      console.log(this.orderId);
      let companyId = this.$getters['user/companies'][0].id;
      let backUrl = `${window.location.protocol}//${window.location.host}/order/info?id=${this.orderId}`;
      backUrl = backUrl.replace(/&/g, encodeURIComponent('&'));
      let data = {
        buyerClient: 'mobile',
        orderId: this.orderId,
        backUrl
      };
      let res = await api.getPays(companyId, this.orderId, data);
      console.log(res);
      if (!res.result) {
        post(res.data.paymentInfo.redirectUrl, res.data.paymentInfo.params);
      } else {
        this.$toast({
          message: '您已支付过，不用再支付了'
        });
      }
      setTimeout(() => {
        this.jy = false;
      }, 10000);
    },
    async handlePayProgress() {
      await this.requestData();
      if (this.orderInfo.status.code === 'paying') {
        this.$toast({
          message: '正在支付中，请稍后再试',
        });
      } else {
        this.$toast({
          message: '支付成功',
        });
      }
    },
    async handlePay() {
      this.jy = true;
      let companyId = this.$getters['user/companies'][0].id;
      let backUrl = `${window.location.protocol}//${window.location.host}/order/info?id=${this.orderId}`;
      backUrl = backUrl.replace(/&/g, encodeURIComponent('&'));
      let data = {
        buyerClient: 'mobile',
        orderId: this.orderId,
        backUrl
      };
      let res = await api.getPay(companyId, this.orderId, data);
      console.log(res);
      post(res.data.paymentInfo.redirectUrl, res.data.paymentInfo.params);
      setTimeout(() => {
        this.jy = false;
      }, 10000);
      // if (res.status == 200) {
      //     location.href = res.redirectUrl;
      // }
      // this.$push({path:'/order/info',query: {id: this.orderId}})
    },
    async handleFahuo() {
      let res = await this.$API.submitDeliver(this.companyId, this.orderId);

      if (res.status == 200) {
        this.$toast.success('发货成功！');
        await this.requestData();
      }
    },
    inActions(state) {
      for (let i = 0; i < this.orderInfo.allowActions.length; i++) {
        const action = this.orderInfo.allowActions[i];
        if (state == action) {
          return true;
        }
      }
      return false;
    },
    showPopChangePrice() {
      if (this.showChangePrice) {
        const isNull = this.prices.some((item) => !item.showPrice);

        if (isNull) {
          this.$toast.fail('请输入价格!');
          return;
        }
        
        this.confirmPrice();
        return;
      }
      this.showChangePrice = true;
    },

    addPrepare(item) {
      const pid = item.productId;
      const packUnitType = item.packUnitType;
      const id = this.orderInfo.id;
      this.$router.push(`/prepare/order?id=${id}&pid=${pid}&unit=${packUnitType}`);
      // this.showPopAddPrepare = true;
      // this.getDeliveryCodes()
    },
    // 确认收货
    async handleReceive() {
      let res = await this.$API.confirmReceive(this.companyId, this.orderId);
      if (res.status == 200) {
        this.$toast.success('成功！');
        await this.requestData();
      }
    },    
    // 卖方货已送达
    async handleDeliveried() {
      let res = await this.$API.confirmDeliveried(this.companyId, this.orderId);
      if (res.status == 200) {
        this.$toast.success('成功！');
        await this.requestData();
      }
    },
    // 确认价格
    async handleAccept() {
      let res = await this.$API.confirmOrder(this.companyId, this.orderId);
      if (res.status == 200) {
        this.$toast.success('确认成功！');
        await this.requestData();
      }
    },
    async handleGot() {
      let res = await this.$API.confirmReceive(this.companyId, this.orderId);
      if (res.status == 200) {
        this.$toast.success('确认货已送达！');
        await this.requestData();
      }
    },
    async confirmPrice() {
      for (let i = 0; i < this.prices.length; i++) {
        const p = this.prices[i];
        p.price = p.showPrice * 100;
      }
      // return console.log(this.prices)
      const loading = this.$toast.loading({
        mask: true,
        message: '加载中...'
      });
      let res = await this.$API.confirmChangePrice(
        this.companyId,
        this.orderId,
        {
          price: this.prices
        }
      );
      if (res.status == 200) {
        this.$toast.success('价格修改成功!');
        this.isEditPrice = false;
        this.showChangePrice = false;
        return this.requestData();
      }
      loading.clear();
    },
    prepareInfo() {
      console.log(this.orderId);
      this.$router.push(`/deliver/order?id=${this.orderId}`);
    },
    async requestData() {
      const loading = this.$toast.loading({
        mask: true,
        message: '加载中...'
      });
      let res = await this.$API.getOrderInfo(this.orderId);
      this.orderInfo = res.data;
      console.log(this.orderInfo);
      this.prices = [];
      for (let i = 0; i < this.orderInfo.items.length; i++) {
        const item = this.orderInfo.items[i];
        this.prices.push({
          productId: item.productId,
          price: item.price,
          showPrice: item.price / 100
        });
      }
      if (this.inActions('changePrice')) {
        this.isEditPrice = true;
      }
      this.histories = res.data.histories || [];
      this.histories = this.histories;
      loading.clear();
    }
  }
};
function post(URL, PARAMS) {
  var temp_form = document.createElement('form');
  temp_form.action = URL;
  // temp_form.target = "_blank";
  temp_form.method = 'post';
  temp_form.style.display = 'none';

  for (var x in PARAMS) {
    var opt = document.createElement('textarea');
    opt.name = x;
    opt.value = PARAMS[x];
    temp_form.appendChild(opt);
  }
  document.body.appendChild(temp_form);
  temp_form.submit();
}
</script>

<style lang="less" scoped>
.c-cell {
  padding: 10px 0;
  box-sizing: border-box;
  margin-bottom: 5px;
  background: #fff;
  margin-top: 5px;
}

.company {
  padding: 0 16px;

  .icon {
    margin-right: 5px;
  }
}

.info {
  margin-top: 10px;
  background: #fafafa;
  padding: 10px;
  margin-bottom: 10px;

  img {
    width: 5rem;
    height: 5rem;
    margin-right: 20px;
    border: 1px solid #ececec;
  }

  span {
    font-size: 16px;
  }
}

.statistics {
  padding: 0 16px;
  text-align: right;

  .price {
    font-size: 16px;
    margin-left: 10px;
  }
}

.cell {
  margin-top: 10px;
}

.operate {
  // padding: 10px 16px;
  background: #fff;
  text-align: right;

  .van-button {
    margin: 10px 16px;
  }
}

// .popwindow {
//   width: 100%;
//   position: fixed;
//   bottom: 0;
//   left: 0;

//   .change-price-window {
//     width: 100%;
//     padding: 20px;
//     box-sizing: border-box;
//     background: #f2f2f2;
//     z-index: 999;
//     position: absolute;
//     bottom: 0;
//     left: 0;

//     .confrim-btn {
//       margin-top: 20px;
//       width: 100%;
//     }
//   }
// }

.red-price {
  color: red;
}

.change-price {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    
    > span {
      margin-right: 5px;
    }
  }

  .van-field {
    padding: 0;
    border: 1px solid #ccc;
  }

  /deep/ .van-field__control {
    padding-left: 10px;
  }
}
</style>
