<template>
    <div class="container flex-column flex-center">
        <img class="icon" src="../assets/success.png"/>
        <p>订单创建成功</p>
        <van-button class="b-link" @click="$router.push(`/order/info?id=${id}`)" type="default" size="large">查看订单详情</van-button>
        <van-button class="b-link" @click="$router.push('/')" type="default" size="large">返回商品页面</van-button>
    </div>
</template>
<script>
export default {
    name: 'Success',
    data(){
        return {
            id: '',
        }
    },
    created(options){
        this.id = this.$route.query.id
    }
}
</script>
<style lang="less" scoped>
    .container {
        background: #fff;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 20px;

        p {
            font-size: 24px;
            text-align: center;
            margin-top:30px;
            margin-bottom: 50px;
        }

        .b-link {
            margin-bottom: 20px;
        }
    }
    .icon {
        width: 128px;
        height: 128px;
        // margin: auto;
        display: block;
        margin-top: 150px;
    }
</style>


