import API from '@/apis/index';

const state = {
    cartList: [],
    latest: {
        shareList: {},
        supplyList: {},
    },
    tempOrders: {},
};

const getters = {
    cartList: (state) => state.cartList,
    latest: (state) => state.latest,
    tempOrders: (state) => state.tempOrders,
};

// mutations
const mutations = {
    // 设置购物车数据(前端数据)
    SET_CART_LIST(state, list) {
        const isObject = Object.prototype.toString.call(list) === "[object Object]";
        state.cartList = isObject ? [] : list;
    },
    // 设置分发直供和共享商品数据(包括更新价格，购物车列表使用)
    SET_LATEST_CART_LIST(state, { extra, items }) {
        state.latest = Object.keys(items).length === 0 ? state.latest : items.reduce(
            (pre, curr) => {
                let list = pre.supplyList;
                let id = curr.sellerId;
                if (curr.sellType === 'share') {
                    list = pre.shareList;
                    id = curr.area.districtId;

                    // 更新价格 共享才更新 直供不更新
                    Object.values(extra.items).forEach((item) => {
                        if (item.id === curr.id) {
                            curr.items.price = item.items.price;
                        }
                    });
                }

                if (list[id]) {
                    list[id].push(curr);
                } else {
                    list[id] = [curr];
                }

                return pre;
            },
            {
                shareList: {},
                supplyList: {},
            },
        );
    },
    // 添加一个商品到购物车
    ADD_CART(state, product) {
        state.cartList.push(product);
    },
    // 给某一个商品修改数量
    UPDATE_PRODUCT_COUNT(state, product) {
        state.cartList.forEach((item) => {
            if (item.id === product.id) {
                item.items.count = product.count;
            }
        });
    },
    // 删除购物车商品
    DELETE_CART_PRODUCT(state, { id, type, distributionId }) {
        const { latest, cartList } = state;
        const index = cartList.findIndex((item) => item.id === id);
        cartList.splice(index, 1);

        const listName = type === 'share' ? 'shareList' : 'supplyList';

        const curr = latest[listName][distributionId];
        if (curr.length === 1) {
            delete latest[listName][distributionId];
            state.latest[listName] = {...latest[listName]};
        } else {
            const index = curr.findIndex((item) => item.id === id);
            curr.splice(index, 1);
        }
    },
    CREATE_TEMP_ORDERS(state, tempOrders) {
        state.tempOrders = tempOrders;
    },
};

const actions = {
    getCart(context, payload = {}) {
        const { isExtra = 0 } = payload;
        const companyId = context.rootGetters['user/companies'][0].id;
        API.getShopCartList(companyId, isExtra).then((res) => {
            // 这里没管价格更新，无所谓，购物车没用这个数据
            context.commit('SET_CART_LIST', res.data.items);

            // 目前只有购物车会传 1
            if (isExtra) {
                // 这里是做购物车列表数据
                context.commit('SET_LATEST_CART_LIST', res.data);
            }
        });
    },
    // {
    //    "id": "510107-525",     共享的就用districtId，直供的就companyID，再加上商品ID 用-号拼接起来
    //    "sellType": "share",   supply或share
    //    "sellerId": "2168",    supply时传这个
    //    "area": {              share时传这个
    //       "provinceId": "510000",
    //       "cityId": "510100",
    //       "districtId": "510107"
    //     },
    //     "items": {
    //       "productId": "525",
    //       "name": "茅台新迎宾酒53°500ml（1箱/6瓶）"
    //       "images": ["http://baijiutangres.oss-cn-qingdao.aliyuncs.com/product/1500429830503"]
    //       "count": "1"
    //       "price": "4368000"  （分）
    //       "shopName": "黄希cs"  (只有直供存储)
    //     }
    //   },
    addCart({ getters, commit }, data) {
        const findExistedProduct = getters.cartList.find(item => item.id === data.id);

        // 如果能找到，则该商品在购物车已存在，count+1
        if (findExistedProduct) {
            commit('UPDATE_PRODUCT_COUNT', {
                id: findExistedProduct.id,
                count: findExistedProduct.items.count + data.items.count,
            });
        } else {
            commit('ADD_CART', data);
        }
    },
    // 购物车页面，更新数量
    updateCartCount({ commit }, payload) {
        commit('UPDATE_PRODUCT_COUNT', payload);
    },
    // 购物车页面，删除某一个
    deleteCartProduct({ commit }, payload) {
        commit('DELETE_CART_PRODUCT', payload);
    },
    // 下单成功后 删除购物车数据
    deleteMultipleProduct({ commit, getters }) {
        getters.tempOrders.forEach((item) => {
            commit('DELETE_CART_PRODUCT', {
                distributionId: item.distributionId,
                id: item.feId,
                type: item.type,
            });
        });

        commit('CREATE_TEMP_ORDERS', {});
    },
    // 保存购物车数据到后端
    async saveCart({ getters, rootGetters }) {
        const companyId = rootGetters['user/companies'][0].id;
        const res = await API.addShopCart(companyId, getters['cartList']);

        return res;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
