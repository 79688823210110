<template>
<div class="login-content flex-column">
    <p>微信登录</p>
    <van-cell-group>
        <van-field v-model="user" required clearable label="用户名" right-icon="question-o" placeholder="请输入用户名" @click-right-icon="$toast('question')" />
        <van-field v-model="pwd" type="password" label="密码" placeholder="请输入密码" required />
        <van-cell-group v-if="requireCaptcha">
            <van-field v-model="captcha" required center clearable label="验证码" placeholder="请输入验证码">
                <!-- <van-button slot="button" size="small" type="primary">发送验证码</van-button> -->
                <a @click="handleShowCaptcha" slot="button" v-if="!showCaptcha">获取验证码</a>
                <img slot="button" @click="handleGetCaptcha" v-if="showCaptcha" :src="captchaUrl" />
            </van-field>
        </van-cell-group>
    </van-cell-group>
    <van-button class="login-btn" type="primary" @click="submit">登录</van-button>
</div>
</template>

<script>
// import _config from '../config'
// import _config from '../../public/config.json'
export default {
    name: 'Login',
    data() {
        return {
            user: '',
            pwd: '',
            captcha: '',
            requireCaptcha: false,
            showCaptcha: false,
            captchaUrl: '',
            openId: ''
        }
    },
    async mounted() {
        this.requireCaptcha = (this.$getters["login/errorCount"] >= 3)
        if (this.requireCaptcha) {
            this.refreshCaptcha()
        }

        const wxCode = this.$route.query.code
        if (wxCode) {
            this.loading = true;
            // login from wx, code to openid
            const ret = await this.$API.getOpenId(wxCode)
            console.log(ret)
            if (ret.data.openId) {
                this.openId = ret.data.openId
                // try login by openId
                if (this.openId) {
                    const res = await this.$API.loginUsingOpenId(this.openId)
                    if (res.status == 200) {
                        this.$store.commit("login/SET_LOGIN_STATE", true);
                        this.$store.commit("user/SET_COMPANIES", res.data.companies);
                        this.$store.commit("user/SET_USERINFO", res.data.userInfo);
                        this.$store.commit("login/CLEAR_LOGIN_ERROR_COUNT");
                        this.$router.push("/");
                    }
                }
            }
            this.loading = false;
        } else {
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbf3212a3d1e5c5e7&redirect_uri=${encodeURIComponent(window.location.origin + '/login')}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        }
    },
    watch: {
        user(val) {
            if (/^1\d{10}$/.test(val) && this.requireCaptcha) {
                this.refreshCaptcha()
            }
            return val
        }
    },
    methods: {
        handleShowCaptcha() {
            if (!(/^1\d{10}$/.test(this.user))) {
                return this.$notify("请填写正确的手机号");
            }
            this.refreshCaptcha()
            this.showCaptcha = true;
        },
        async handleGetCaptcha() {
            this.refreshCaptcha()
        },
        async submit() {
            this.refreshCaptcha()
            if (!(this.user && this.pwd)) {
                return this.$notify('请填写账号和密码后登录');
            }
            if (this.requireCaptcha && !this.captcha) {
                return this.$notify('请填写验证码');
            }
            if (!this.openId) {
                return this.$notify('未获取到openid');
            }
            this.loading = true;
            let res = await this.$API.doLogin(this.user, this.pwd, this.captcha);
            if (res.status == 200) {
                this.$store.commit("login/SET_LOGIN_STATE", true);
                this.$store.commit("user/SET_COMPANIES", res.data.companies);
                this.$store.commit("user/SET_USERINFO", res.data.userInfo);
                this.$store.commit("login/CLEAR_LOGIN_ERROR_COUNT");
                // bind wx openid with user
                let reg = await this.$API.bindOpenId(this.user, this.pwd, this.openId)
                if (reg.status != 200) {
                    return this.$notify('绑定OpenId失败');
                }
                this.$router.push("/");
            } else {
                this.refreshCaptcha()
                this.$store.commit("login/SET_LOGIN_ERROR_COUNT");
                if (this.$getters["login/errorCount"] >= 3) {
                    this.requireCaptcha = true;
                }
            }
            this.loading = false;
        },
        refreshCaptcha() {
            this.captchaUrl = window.PREFIX + `/business/api/system/captcha?id=${this.user}`
            // &rnd=` + Math.random()
        }
    }
}
</script>

<style lang="less" scoped>
.login-content {
    padding: 20px;
    padding-top: 35%;

    p {
        text-align: center;
        font-size: 20px;
        padding: 20px;
    }

    img {
        height: 30px;
        width: 100px;
        border: 1px solid #eee;
    }

    .login-btn {
        margin-top: 20px;
        text-align: center;
    }
}
</style>
