import axios from '../common/axios'
axios.defaults.headers.post['Content-Type'] = 'application/json';
export default {
    // 库存列表
    async getStockList(companyId, productId, params) {
        return await axios.get(`/business/api/companies/${companyId}/products/${productId}/codes`, {
            params: {
                ...params
            }
        })
    },
    // 库存详情
    // /business/api/companies/{companyId}/products/{productId}/codes/{codeId}
    async getStockDetail(companyId, productId, codeId, params) {
        return await axios.get(`/business/api/companies/${companyId}/products/${productId}/codes/${codeId}`, {
            params: {
                ...params
            }
        })
    },
    // /business/api/companies/{companyId}/products/{productId}/codes/boxes
    async storageStockBoxes(companyId, productId, params) {
        return await axios({
            url: `/business/api/companies/${companyId}/products/${productId}/codes/boxes`,
            method: 'POST',
            data: {
                ...params
            }
        })
    },
    async storageStockBottles(companyId, productId, params) {
        return await axios({
            url: `/business/api/companies/${companyId}/products/${productId}/codes/bottles`,
            method: 'POST',
            data: {
                ...params
            }
        })
    },
    // /business/api/companies/{companyId}/products/{productId}/boxes/{boxId}/split
    async splitBox(companyId, productId, boxId, params) {
        return await axios({
            url: `/business/api/companies/${companyId}/products/${productId}/boxes/${boxId}/split`,
            method: 'POST',
            data: {
                ...params
            }
        })
    },
    // /business/api/products
    async products(companyId, params) {
        return await axios.get(`/business/api/companies/${companyId}/productsonsell`, {
            params: {
                ...params
            }
        })
    },
    // 售出
    // /business/api/companies/{companyId}/products/{productId}/codes/{codeId}/sell
    async stockSell(companyId, productId, codeId) {
        return await axios.put(`/business/api/companies/${companyId}/products/${productId}/codes/${codeId}/sell`)
    },
    // 库存编辑
    // /business/api/companies/{companyId}/products/{productId}/codes/{codeId}
    async editCodes(companyId, productId, codeId, params){
        return await axios({
            url: `/business/api/companies/${companyId}/products/${productId}/codes/${codeId}`,
            method: 'PUT',
            data: {
                ...params
            }
        })
    },
}
// /business/api/companies/{companyId}/products/{productId}/codes