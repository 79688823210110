import axios from '../common/axios'
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
    async getPermissionList(companyId) {
        let res = await axios.get(`/business/api/companies/${companyId}/permission`)
        return res.data.items || []
    },

    async getCompanyMemberList(companyId, page, pageSize, searchKey) {
        let res = await axios({
            url: `/business/api/companies/${companyId}/members?searchKey=${searchKey}&page=${page}&pageSize=${pageSize}`,
            method: "get",
            params: {
                searchKey: this.searchKey,
                page: this.page,
                pageSize: this.pageSize
            }
        });
        return res.data || {}
    },
    async putDisabledUser(companyId, uid) {
        let res = await axios.put(`/business/api/companies/${companyId}/members/${uid}/status`)
        return res
    },
    // 添加用户
    async addUser(companyId, params) {
        let res = await axios({
            url: `/business/api/companies/${companyId}/members`,
            method: 'post',
            data: params
        })
        return res
    },

    // 删除员工
    async deleteUser(companyId, uid) {
        let res = await axios.delete(`/business/api/companies/${companyId}/members/${uid}`)
        return res.data.result
    },

    async getCompaniesPermissionList(companyId){
        return await axios.get(`/business/api/companiesPermission/${companyId}`)
    },
}