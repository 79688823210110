<template>
    <div id="map-container">
      <iframe 
        id="mapPage" 
        width="100%" 
        height="100%" 
        frameborder=0
        :src="mapAddr"
      >
      </iframe>
    </div>
</template>
<script>
export default {
    name: 'MapLocation',
    data() {
      return {
        mapAddr: 'https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=2F4BZ-IQUWP-TO4DZ-VFMHI-HLSIE-OQBPV&referer=BusinessWechat',
      }
    },
    mounted() {
      const { lng, lat } = this.$route.query;
      if (lng && lat) {
        this.mapAddr = `${this.mapAddr}&coord=${lat},${lng}`;
      }

      const self = this;

      window.addEventListener('message', function(event) {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        const loc = event.data;
        //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
        if (loc && loc.module == 'locationPicker') {
          console.log('location', loc);
          const { latlng: { lat, lng }, poiaddress } = loc;
          const address = window.encodeURI(poiaddress);

          self.$router.push(`/admin?lng=${lng}&lat=${lat}&address=${address}`);
        }
      }, false);
    },
}
</script>

<style lang="less" scoped>
   #map-container {
     width: 100%;
     height: 100vh;
     min-height: 400px;
   }
</style>

