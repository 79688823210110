import axios from '../common/axios'
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
    async getGoodsList(companyId, pagion = { page: 1, pageSize: 20 }, search = { searchKey: '', brandId: '', seriesId: '', sellStatus: '' }) {
        let res = await axios.get(`/business/api/companies/${companyId}/goods`, {
            params: {
                page: pagion.page,
                pageSize: pagion.pageSize,
                searchKey: search.searchKey,
                brandId: search.brandId,
                seriesId: search.seriesId,
                sellStatus: search.sellStatus,
            }
        })
        return res
    },

    async getBrandSeries(parentId) {
        let res = await axios.get('/business/api/brandseries', {
            params: {
                parentId,
                page:1,
                pageSize: 9999
            }
        })
        return res
    },

    async getCategories(parentId) {
        let res = await axios.get('/business/api/categories', {
            params: {
                parentId,
                page:1,
                pageSize: 9999
            }
        })
        return res
    },
    async putSellStatus(companyId, productId, status, type) {
        let res = await axios.put(`/business/api/companies/${companyId}/goods/${productId}/sellstatus`, {
            status,
            type
        })
        return res
    },
    async getProducts(brandId) {
        let res = await axios.get('/business/api/products', {
            params: {
                brandId,
                page:1,
                pageSize: 9999
            }
        })
        return res
    },

    async addGoods(companyId, productId, price, isSupply = false, isShared = false) {
        let res = await axios({
            url: `/business/api/companies/${companyId}/goods`,
            method: 'POST',
            data: {
                productId,
                price,
                isSupply,
                isShared
            }
        })
        return res
    },

    async getProductDetail(companyId, productId) {
        let res = await axios.get(`/business/api/companies/${companyId}/goods/${productId}`)
        return res
    },
    async getGoodsInfo(companyId, productId) {
        return await axios.get(`/business/api/companies/${companyId}/goods/${productId}`)
    },
    // /business/api/trade/orders
    async createTrade(params){
        return await axios({
            url: `/business/api/trade/orders`,
            method: 'post',
            data: {
                ...params
            }
        })
    },
    // 标准商品详情
    // /business/api/products/{productId}
    async getProductInfo(productId){
        return axios.get(`/business/api/products/${productId}`)
    },
    // 获取采购-分享交易商品详情
    async getSharedProductInfo(productId, type, destination){
        return await axios.get(`/business/api/goods/${productId}`, { 
            params: {
                productId,
                type,
                ...destination
            } 
        })
    },
    // /business/api/companies/{companyId}/goods/{productId}
    async getBuyerProductInfo(companyId,productId,type){
        return await axios.get(`/business/api/companies/${companyId}/goods/${productId}`, {
            params: {
                type
            }
        })
    },
    // /boss/api/orders/{orderId}/prepareOrder
    async patchPrepareOrder(orderId, codeIds){
        return await axios({
            url: `/boss/api/orders/${orderId}/prepareOrder`,
            method: 'PATCH',
            data: {
                codeIds
            }
        })
    },
    // 获取商户价格区域
    // /business/api/companies/{companyId}/companyArea
    async getCompanyArea(companyId){
        return  await axios.get(`/business/api/companies/${companyId}/companyArea`)
    }
}