import axios from '../common/axios'
axios.defaults.headers.post['Content-Type'] = 'application/json';
export default {
    // 微信openid登陆
    async loginUsingOpenId(openId) {
        return await axios({
            url: '/business/api/wx/login',
            method: 'POST',
            data: {
                openId
            }
        })
    },
    // 绑定openId
    async bindOpenId(username, password, openId) {
        return await axios({
            url: '/business/api/wx/bindopenid',
            method: 'POST',
            data: {
                username,
                password,
                openId
            }
        })
    },
    // 获取openid
    async getOpenId(code) {
        return await axios.get(`/business/api/wx/getopenid`, {
            params: {
                code
            }
        })
    },
    // 获取appid
    async getAppId(companyId, productId, params) {
        return await axios.get(`/business/api/wx/getappid`)
    },   
}
