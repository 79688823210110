<template>
<div class="v-cell flex-row">
    <span class="title">{{title}}</span>
    <div class="flex-row content">
        <slot/>
    </div>
</div>
</template>

<script>
export default {
    name: 'vCell',
    data(){
        return {

        }
    },
    props: {
        title: String,
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="less">
.v-cell {
    background: #fff;
    padding: 10px 16px;
    box-sizing: border-box;
    
    &:first-child{
        
    }
    .title {
        width: 100px;
        font-size: 16px;
        font-family: "PingFang SC";
        color: #323233;
    }

    .content {
        flex: 1;
        color: #969799;
    }
}
</style>
