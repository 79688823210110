import axios from '../common/axios'
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
    async doLogin(user, pwd, captcha) {
        let res = await axios({
            url: '/business/api/login',
            method: 'POST',
            data: {
                username: user,
                password: pwd,
                captcha
            },
            unauth: false,
        })
        return res
    },
    async getCaptcha(id) {
        let res = await axios.get('/business/api/system/captcha?id=' + id)
        return res.data || null
    },
}