<template>
<div>
    <van-nav-bar
        title="购物车"
        left-text="返回"
        @click-left="$router.back()"
        left-arrow
        :fixed="true"
        placeholder
    />

    <van-tabs>
        <van-tab title="共享商品">
            <template v-if="list.share.length > 0">
                <ShoppingCarItem
                    type="share"
                    v-for="item in list.share"
                    :key="item.id"
                    :list="item"
                />
            </template>
            <div v-else class="shopping-car__not-proudct">暂无商品</div>
        </van-tab>
        <van-tab title="直供商品">
            <template v-if="list.supply.length > 0">
                <ShoppingCarItem
                    type="supply"
                    v-for="item in list.supply"
                    :key="item.id"
                    :list="item"
                />
            </template>
            <div v-else class="shopping-car__not-proudct">暂无商品</div>
        </van-tab>
    </van-tabs>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ShoppingCarItem from '@/components/ShoppingCarItem.vue';

export default {
    name: 'ShoppingCar',
    components: {
        ShoppingCarItem,
    },
    async mounted() {
        this.$store.dispatch('cart/getCart', {
            isExtra: 1,
        });
    },
    computed: {
        ...mapGetters({
            latest: 'cart/latest',
        }),
        list() {
            return {
                share: Object.values(this.latest.shareList),
                supply: Object.values(this.latest.supplyList),
            };
        },
    },
}
</script>

<style lang="less" scoped>
.shopping-car {
    &__not-proudct {
        text-align: center;
        margin-top: 20px;
    }
}
</style>