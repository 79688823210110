<template>
<div>
    <van-nav-bar :title="isEdit ? '编辑收货地址' : '新增收货地址'" left-text="返回" @click-left="$router.back()" left-arrow :fixed="true" placeholder >
    </van-nav-bar>

    <van-cell-group>
        <van-cell title="选择收货区域" is-link @click="showAreaSelect = true" :value="areaName" size="mini" />
        <van-field required label="收货人" v-model="addressInfo.name" placeholder="收货人" />
        <van-field required label="手机号码" v-model="addressInfo.tel" placeholder="手机号码" />
        <van-field required label="详细地址" type="textarea" v-model="addressInfo.addressDetail" placeholder="详细地址" />
        <!-- <van-field label="别名" v-model="alias" placeholder="别名" /> -->
        <van-switch-cell :disabled="!saveAddress" v-model="addressInfo.isDefault" title="设为默认地址" />
        <van-switch-cell v-model="saveAddress" title="保存到地址簿" />
    </van-cell-group>

    <van-button @click="onSave" style="margin-top: 10px;" type="danger" size="large"> 保存 </van-button>

    <!-- <van-field label="地址别名" v-model="alias" placeholder="别名" /> -->
    <!-- show-delete -->
    <!-- <address-edit :area-list="areaList" show-set-default :search-result="searchResult" @save="onSave" @delete="onDelete" @change-detail="onChangeDetail" :address-info="addressInfo" /> -->
    <div class="areaSelect">
        <transition name="van-fade">
            <van-overlay v-show="showAreaSelect" :show="showAreaSelect" @click="showAreaSelect = false"></van-overlay>
        </transition>
        <transition name="van-slide-up">
            <van-area @cancel="showAreaSelect = false" :value="''+addressInfo.areaCode" @confirm="confirmArea" v-show="showAreaSelect" class="area" :area-list="areaList" />
        </transition>
    </div>
</div>
</template>

<script>
import AreaList from '../common/area.js'
import baseMixin from '../mixins/base.js'
export default {
    name: 'Address',
    mixins: [baseMixin],
    components: {},
    data() {
        return {
            alias: '',
            searchResult: [],
            areaList: {},
            isEdit: false,
            editId: '',
            addressInfo: {
                name: '',
                tel: '',
                addressDetail: '',
                areaCode: '',
                isDefault: false,
                zipcode: '',
            },
            showAreaSelect: false,
            saveAddress: false,
        }
    },
    computed: {
        areaName() {
            if (!this.addressInfo.areaCode) return ''
            const provinceId = this.addressInfo.areaCode.substring(0, 2) + '0000'
            const cityId = this.addressInfo.areaCode.substring(0, 4) + '00'
            return `${(this.id2name(provinceId) || '省')} ${(this.id2name(cityId) || '市')} ${(this.id2name(this.addressInfo.areaCode)|| '区')}`
        }
    },
    async mounted() {
        this.areaList = AreaList
        if (this.$route.name == 'EditAddress') {
            this.isEdit = true
            this.editId = this.$route.query.id

            const loading = this.$toast.loading({
                mask: true,
                message: '加载中...'
            });
            let res = await this.$API.getShippingaddresses(this.companyId)
            const shippingaddressesList = res.data.items
            for (let i = 0; i < shippingaddressesList.length; i++) {
                const address = shippingaddressesList[i];
                if (address.id == this.editId) {
                    // 设置默认值
                    this.addressInfo.name = address.name,
                        this.addressInfo.tel = address.mobile,
                        this.addressInfo.addressDetail = address.address,
                        // this.alias = address.alias
                        this.addressInfo.areaCode = address.districtId
                    this.addressInfo.isDefault = address.isDefault == 1 ? true : false
                }
            }
            loading.clear()
        } else {
            this.addressInfo.isDefault = false
        }
    },
    methods: {
        onChangeDetail() {

        },
        onDelete() {

        },
        async onSave() {
            const e = this.addressInfo
            if (!e.areaCode) {
                return this.$notify("请选择收货区域");
            }
            let addressData = {
                name: e.name,
                mobile: e.tel,
                address: e.addressDetail,
                zipcode: e.zipcode,
                // alias: this.alias,
                provinceId: e.areaCode.substring(0, 2) + '0000',
                cityId: e.areaCode.substring(0, 4) + '00',
                districtId: e.areaCode,
            }
            if (this.saveAddress) {
                
                // 新增默认为默认地址
                if (!this.isEdit) {
                    const loading = this.$toast.loading({
                        mask: true,
                        message: '加载中...'
                    });
                    let res = await this.$API.addShippingaddresses(this.companyId, addressData, e.isDefault)
                    loading.clear()
                    if (res.status == 200) {
                        this.$toast.success('添加成功');
                        setTimeout(() => {
                            this.$router.back()
                        }, 500)
                    }
                } else {
                    var isDefault = e.isDefault ? 1 : 0
                    addressData.isDefault = isDefault
                    const loading = this.$toast.loading({
                        mask: true,
                        message: '加载中...'
                    });
                    let res = await this.$API.editShippingaddresses(this.companyId, this.editId, addressData)
                    loading.clear()
                    if (res.status == 200) {
                        this.$toast.success('编辑成功');
                        setTimeout(() => {
                            this.$router.back()
                        }, 500)
                    }
                }
            } else {
                // return console.log(addressData, )
                addressData.id = -2;
                this.$store.commit("address/SET_CURRENT_ADDRESS", addressData);
                this.$router.push(this.$getters["user/currentPage"])
            }

        },
        confirmArea(e) {
            this.addressInfo.areaCode = e[2].code
            this.showAreaSelect = false
        },
    }
}
</script>

<style lang="less" scoped>
.addr-name {
    font-size: 16px;
    margin-right: 10px;
}

.areaSelect {
    width: 100%;
    position: fixed;
    bottom: 50px;
    left: 0;

    .area {
        z-index: 2001;
    }
}
</style>
