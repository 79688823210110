import axios from '../common/axios'
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
    // /business/api/companies/{companyId}/orders
    async getOrderList(companyId, pagion = { page: 1, pageSize: 20 }, query) {
        let res = await axios.get(`/business/api/companies/${companyId}/orders`, {
            params: {
                page: pagion.page,
                pageSize: pagion.pageSize,
                ...query
            }
        })
        return res
    },
    // http://yapi.baijiutang.com:3000/project/25/interface/api/1764
    async getOrderListOfShared(companyId, memberId, actionCode, pagion = { page: 1, pageSize: 20 }) {
        // actionCode: 备货中 delivery ， 配送中 delivering ， 已完成 completed
        let res = await axios.get(`/business/api/company/${companyId}/orderShareMarks`, {
            params: {
                onlyInStep: true,
                memberId, 
                actionCode,
                page: pagion.page,
                pageSize: pagion.pageSize,
            }
        })
        return res
    },
    // /business/api/companies/{companyId}/orders/{orderId}/deliver
    async submitDeliver(companyId, orderId) {
        let res = await axios.post(`/business/api/companies/${companyId}/orders/${orderId}/deliver`)
        return res
    },
    // /business/api/companies/{companyId}/orders/{orderId}/deliveryCodes
    async getDeliveryCodes(companyId, orderId) {
        let res = await axios.get(`/business/api/companies/${companyId}/orders/${orderId}/deliveryCodes`)
        return res
    },
    // /business/api/companies/{companyId}/orders/{orderId}/codeHistory
    async getCodeHistory(companyId, orderId) {
        let res = await axios.get(`/business/api/companies/${companyId}/orders/${orderId}/codeHistory`)
        return res
    },
    // /business/api/trade/orders/{orderId}
    async getOrderInfo(orderId) {
        let res = await axios.get(`/business/api/trade/orders/${orderId}`)
        return res
    },
    // 取消订单
    // /business/api/companies/{companyId}/orders/{orderId}/cancelOrder
    async patchCancelOrder(companyId, orderId) {
        let res = await axios.patch(`/business/api/companies/${companyId}/orders/${orderId}/cancelOrder`)
        return res
    },
    // /business/api/companies/{companyId}/orders/{orderId}/confirm
    // /business/api/companies/{companyId}/orders/{orderId}/confirm
    async confirmOrder(companyId, orderId) {
        return await axios.patch(`/business/api/companies/${companyId}/orders/${orderId}/confirm`)
    },
    // 接单 
    async acceptOrder(companyId, orderId) { 
        return await axios.put(`/business/api/companies/${companyId}/orders/${orderId}/acceptOrder`); 
    }, 
    // 不能供应 
    async notAvailable(companyId, orderId) { 
        return await axios.put(`/business/api/companies/${companyId}/orders/${orderId}/unsupply`); 
    },
    // /business/api/companies/{companyId}/orders/{orderId}/changePrice
    async confirmChangePrice(companyId, orderId, params) {
        return await axios({
            url: `/business/api/companies/${companyId}/orders/${orderId}/changePrice`,
            method: 'PATCH',
            data: params
        })
    },

    // /business/api/companies/{companyId}/orders/{orderId}/prepareOrder
    async confirmDelivery(companyId, orderId, codeIds, productId) {
        return await axios({
            url: `/business/api/orders/pickOrder/update/1.0.0`,
            method: 'POST',
            data: {
                codeIds,
                productId,
                orderId
            }
        })
    },
    // /business/api/companies/{companyId}/orders/{orderId}/deliveryCodes
    async getDeliveryCodes(companyId, orderId) {
        return await axios.get(`/business/api/companies/${companyId}/orders/${orderId}/deliveryCodes`)
    },
    // 根据订单获取可选库存
    // /business/api/companies/{companyId}/orders/{orderId}/codes
    async getOrderCodes(companyId, orderId, productId,page,pageSize,searchKey=""){
        return await axios.get(`/business/api/companies/${companyId}/orders/${orderId}/codes?productId=${productId}&page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`)
    },
    // 确认收货
    // /business/api/companies/{companyId}/orders/{orderId}/receive
    async confirmReceive(companyId,orderId){
        return await axios({
            url: `/business/api/companies/${companyId}/orders/${orderId}/receive`,
            method:"PATCH"
        })
    },    
    // 确认收货
    // /business/api/companies/{companyId}/orders/{orderId}/deliveried
    async confirmDeliveried(companyId,orderId){
        return await axios({
            url: `/business/api/companies/${companyId}/orders/${orderId}/deliveried`,
            method:"POST"
        })
    },
    // /business/api/companies/{companyId}/codes/{code}/history
    // 流通朔源
    async getCodeCirculateHistory(companyId,code){
        return await axios.get(`/business/api/companies/${companyId}/codes/${code}/history`)
    }

}