const state = {
    currentAddress: {}
}

const getters = {
    currentAddress: (state, getters) => {
        return state.currentAddress
    },
}

// mutations
const mutations = {
    SET_CURRENT_ADDRESS(state, status) {
        state.currentAddress = status
        console.log('SET_CURRENT_ADDRESS')
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}