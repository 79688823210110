import login from './login'
import staff from './staff'
import product from './product'
import distributor from './distributor'
import upload from './upload'
import order from './order'
import stock from './stock'
import purchase from './purchase'
import pay from './pay'
import wx from './wx'
import admin from './admin'

export default { ...login, ...staff, ...product, ...distributor, ...upload, ...order, ...stock,...purchase, ...wx ,...pay, ...admin}