<template>
    <div class="shopping-item">
        <p class="shopping-item__title">
            <img src="../assets/shopping.png" />
            {{
                isSupply
                ? list[0].items.shopName
                : `${regionNames[list[0].area.provinceId]}/${regionNames[list[0].area.cityId]}/${regionNames[list[0].area.districtId] }`
            }}
        </p>

        <van-checkbox-group v-model="checked" ref="checkboxGroup">
            <div
                class="shopping-item__item"
                v-for="item in list"
                :key="item.id"
            >
                <van-checkbox
                    class="shopping-item__item-checkbox"
                    :name="item.id"
                    shape="square"
                />
                <img :src="item.items.images[0]" />
                <div class="shopping-item__item-info">
                    <p>{{item.items.name}}</p>
                    <p>￥{{isSupply ? '0' : stringMoney(item.items.price)}}</p>
                </div>
                <div class="shopping-item__item-operate">
                    <van-stepper
                        :value="item.items.count"
                        @change="(value) => countChange(value, item)"
                        size="mini"
                        :min="1"
                    />
                    <a
                        class="shopping-item__item-operate-delete"
                        @click="handleDelete(item)"
                    >
                        删除
                    </a>
                </div>
            </div>
        </van-checkbox-group>
        <div class="shopping-item__all">
            <van-checkbox
                v-model="checkAll"
                @click="handleCheckAllChange"
                shape="square"
            >
                全选
            </van-checkbox>
            <div>
                共{{selectProduct.length}}件商品 实付款:
                <span class="shopping-item__all-price">￥{{isSupply ? '0' :  selectProduct.price}}</span>
            </div>
        </div>
        <div class="shopping-item__btn">
            <van-button @click="handleSubmit">确认下单</van-button>
        </div>
    </div>
</template>

<script>
import { CodeToText } from 'element-china-area-data';
import baseMixin from '../mixins/base.js';

export default {
    name: 'ShoppingCarItem',
    mixins: [baseMixin],
    props: {
        list: Array,
        type: String,
    },
    data(){
        return {
            regionNames: CodeToText,
            checked: [],
            checkAll: false,
        }
    },
    computed: {
        isSupply() {
            return this.type === 'supply';
        },
        selectProduct() {
            let price = 0;
            let length = 0;

            this.checked.forEach((curr) => {
                const findExistedProduct = this.list.find(item => item.id === curr);

                price += parseInt(findExistedProduct.items.count * findExistedProduct.items.price, 10);
                length += parseInt(findExistedProduct.items.count, 10);
            });
            return {
                price: this.stringMoney(price),
                length,
            }
        },
    },
    watch: {
        checked: function(val) {
            this.checkAll = val.length === this.list.length;
        },
    },
    methods: {
        countChange(value, item) {
            this.$store.dispatch('cart/updateCartCount', {
                id: item.id,
                count: value,
            }).then(() => {
                this.$store.dispatch('cart/saveCart');
            });
        },
        handleCheckAllChange(value) {
            this.$refs.checkboxGroup.toggleAll(!this.checkAll);
        },
        handleDelete(item) {
            this.$store.dispatch('cart/deleteCartProduct', {
                id: item.id,
                type: this.type,
                distributionId: this.isSupply ? item.sellerId : item.area.districtId,
            }).then(() => {
                this.$store.dispatch('cart/saveCart');
            });
        },
        handleSubmit() {
            if (this.checked.length === 0) {
                return this.$toast.fail('请至少选择一件商品');
            }
            const products = this.checked.map((item) => {
                const findExistedProduct = this.list.find((l) => l.id === item);

                return {
                    feId: findExistedProduct.id,
                    type: findExistedProduct.sellType,
                    id: findExistedProduct.items.productId,
                    count: findExistedProduct.items.count,
                    distributionId: this.isSupply ? findExistedProduct.sellerId : findExistedProduct.area.districtId,
                }
            });
            this.$store.commit('cart/CREATE_TEMP_ORDERS', products);

            const query = {
                type: this.isSupply ? 'supply' : 'shared',
            }

            if (this.isSupply) {
                Object.assign(query, {
                    companyid: this.list[0].sellerId,
                });
            } else {
                Object.assign(query, {
                    cityId: this.list[0].area.cityId,
                    provinceId: this.list[0].area.provinceId,
                    districtId: this.list[0].area.districtId,
                });
            }
            this.$router.push({
                path: '/confirm/muilti',
                query,
            });
        },
    }
}
</script>

<style lang="less">
.shopping-item {
    background-color: #fff;
    padding-bottom: 10px;
    margin-bottom: 15px;

    &__title {
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        background-color: #cccccc;
        display: flex;
        align-items: center;
        padding: 0 10px;

        img {
            width: 20px;
            height: 18px;
            margin-right: 20px;
        }
    }

    &__item {
        display: flex;
        padding: 10px;
        background-color: #fafafa;

        &-checkbox {
            flex-shrink: 0;
        }

        img {
            flex-shrink: 0;
            width: 80px;
            height: 80px;
            margin-right: 15px;
            margin-left: 5px;
        }

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 5px;
        }

        &-operate {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &-delete {
                color: #999999;
                text-align: right;
            }
        }
    }

    &__all {
        display: flex;
        justify-content: space-between;
        padding: 10px;

        &-price {
            color: #D9001B;
            font-weight: 700;
            font-size: 20px;
        }
    }

    &__btn {
        padding: 0 10px;
        text-align: right;
        
        .van-button {
            width: 140px;
            height: 40px;
            line-height: 40px;
            border-radius: 5px;
            background-color: #ff3300;
            color: #fff;
            border-color: #ff3300;
        }
    }
}
</style>
