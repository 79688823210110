import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vant from 'vant';
import 'vant/lib/index.css';
import './less/index.less';
import API from './apis/index';
import axios from './common/axios';


Vue.prototype.axios = axios;
axios.get('/config.json').then(res => {
  if (res.status == 200) {
    window.PREFIX = res.data.prefix;
  }
  Vue.use(Vant);
  Vue.config.productionTip = false;
  Vue.prototype.$getters = store.getters;
  Vue.prototype.$API = API;

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});
