import axios from '../common/axios'
axios.defaults.headers.post['Content-Type'] = 'application/json';
export default {
	/**
	 * 添加购物车
	 *
	 * @param {*} companyId 
	 * @param {*} data 
	 */
	async addShopCart(companyId, data) {
		return await axios({
			method: 'POST',
			url: `/business/api/companies/${companyId}/shopCart`,
			data,
		})
	},
	/**
	 * 购物车列表
	 * 
	 * @param {string} companyId 
	 * @param {number} isExtra 返回extra数据 0 不需要 1 需要
	 */
	async getShopCartList(companyId, isExtra = 0) {
		return await axios.get(`/business/api/companies/${companyId}/shopCart`, {
			params: {
				isExtra,
			}
		});
	},
	// 支付
	async getPay(companyId,orderId,data){
		return await axios({
			method: 'POST',
			url: `/business/api/companies/${companyId}/orders/${orderId}/launchPayment`,
			data
		})
	},
	// 重新支付
	async getPays(companyId,orderId,data){
		let res = await axios({
			url: `/business/api/companies/${companyId}/orders/${orderId}/relaunchPayment`,
			method: "POST",
			data
		})
		return res
	},
	async skipPay(companyId, orderId){
		return await axios({
			url:`/business/api/companies/${companyId}/orders/${orderId}/skipPay`,
			method: 'PATCH',
		})
	}
}