import axios from '../common/axios'
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
    async getUserInfo(companyId, memberId){
        const res =  await axios.get(`/business/api/companies/${companyId}/members/${memberId}`)
        if (res.status == 200) {
            return res.data.user
        }
        return null
    },
    
    async getCompanyInfo(companyId, memberId){
        const res = await axios.get(`/business/api/company`)
        if (res.status == 200) {
            return res.data
        }
        return null
    },

    async getCompanyLocation(companyId){
        const res = await axios.get(`/business/api/companies/${companyId}/location`)
        if (res.status == 200) {
            return res.data
        }
        return null
    },

    async getLocation(companyId, longitude, latitude, address){
        const res = await axios({
            url: `/business/api/companies/${companyId}/location`,
            method: 'POST',
            data: {
                longitude,
                latitude,
                address,
            }
        })
        
        if (res.status == 200) {
            return res.data
        }
        return null
    },

    // 修改密码
    async updatePwd(companyId, memberId, data) {
        let res = await axios({
            url: `/business/api/companies/${companyId}/member/${memberId}/password`,
            method: "PUT",
            data
        })
        return res
    },
}