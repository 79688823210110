import Money from "../common/money";
import Citys from "../common/city";
import moment from 'moment'

const baseMixin = {
    data() {
        return {
            companyId: -1,
            page: 1,
            pageSize: 20,
        }
    },
    mounted() {
        if ((this.$store, this.$getters["user/companies"][0])) {
            this.companyId = this.$getters["user/companies"][0].id;
        }
    },
    methods: {
        stringMoney(price) {
            return Money(price)
        },
        unix2LocalTime(time) {
            return moment(time * 1000).format('YYYY-MM-DD HH:mm:ss')
        },
        id2name(id) {
            return Citys.id2name(id)
        },
    }
}

export default baseMixin 
