<template>
<div>
    <div class="content">
        <router-view />
        <div v-show="active !== 4" @click="$router.push('/shopping-car')" class="cart">
            <van-icon name="shopping-cart-o" />
            <div class="badge" v-if="cartNum">{{cartNum}}</div>
        </div>
    </div>

    <van-tabbar v-model="active" @change="tabChange">
        <van-tabbar-item icon="home-o">商品</van-tabbar-item>
        <van-tabbar-item v-show="includes(permissions, 'purchase')" icon="search">采购订单</van-tabbar-item>
        <van-tabbar-item v-show="includes(permissions, 'order')" icon="friends-o">供应订单</van-tabbar-item>
        <van-tabbar-item v-show="includes(permissions, 'order')" icon="orders-o">云店订单</van-tabbar-item>
        <van-tabbar-item icon="contact">账号</van-tabbar-item>
    </van-tabbar>
</div>
</template>

<script>
import { get, includes } from "lodash"

export default {
    name: 'Layout',
    data() {
        return {
            active: '',
            permissions: []
        }
    },
    computed: {
        cartNum() {
            let cartList = this.$getters['cart/cartList']
            return cartList.length || 0;
        },
    },
    mounted() {
        switch (this.$route.name) {
            case "Admin":
                this.active = 4
                break;
            case "OrderConsumer":
                this.active = 3
                break;
            case "OrderSeller":
                this.active = 2
                break;
            case "OrderBuyer":
                this.active = 1
                break;
            case "Home":
                this.active = 0
                break;
        }

        // 进入时获取购物车数据
        this.$store.dispatch('cart/getCart');

        this.getPermissions();
    },
    methods: {
        includes,
        getPermissions() {
            const userInfo = this.$getters['user/userInfo'];
            this.permissions = get(userInfo, 'permissions', [])
        },
        tabChange(e) {
            switch (e) {
                case 0:
                    this.$router.push('/')
                    break;
                case 1:
                    this.$store.commit("tab/SET_BUYER_STATE", 0);
                    this.$router.push(`/blank?path=/order-list/buyer`)
                    break;
                case 2:
                    this.$store.commit("tab/SET_SELLER_STATE", 0);
                    this.$router.push(`/blank?path=/order-list/seller`)
                    break;
                case 3:
                    this.$store.commit("tab/SET_CONSUMER_STATE", 0);
                    this.$router.push(`/blank?path=/order-list/consumer`)
                    break;
                case 4:
                    this.$router.push(`/admin`)
                    break;
            }
        }
    }
}
</script>

<style lang="less" scoped>
.content {
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    padding-bottom: 50px;
    box-sizing: border-box;

    .cart {
        position: fixed;
        bottom: 60px;
        right: 20px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0px 0px 5px #333333;
        display: flex;
        align-items: center;
        justify-content: center;

        .van-icon-shopping-cart-o {
            font-size: 24px;
        }

        .badge {
            color: #fff;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            right: -5px;
            top: -5px;
            position: absolute;
            background: red;
            text-align: center;
            line-height: 20px;
        }
    }
}

.van-tabbar-item--active {
    color: #f3373b;
}
</style>
