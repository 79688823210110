<template>
  <div class="container flex-column">
    <van-nav-bar
      :title="type == 'deliver' ? '备货详情' : '选择库存商品备货'"
      left-text="返回"
      @click-left="$router.back()"
      left-arrow
      :fixed="true"
      placeholder
    ></van-nav-bar>
    <div class="head flex-row space-between" v-if="orderInfo.items && type == 'prepare'">
      <div style="text-align:left">{{ productDetail.name }}</div>
      <div style="text-align: right;">库存总量: {{ total }} {{ productDetail.unitText }}</div>
    </div>
    <div class="codes-box">
      <div>已选库存</div>

      <div class="selected-box"  v-show="selectedCodes.length>0">
        <div style="tag-box" v-for="code in selectedCodes" :key="code.key">
          <div class="selected-code-box">
            <div class="van-notice-bar__wrap">
              <div class>{{code.name}}</div>
            </div>
            <i @click="onClose(code)" class="van-icon van-icon-cross van-notice-bar__right-icon"></i>
          </div>
        </div>
      </div>
      <van-search
        clearable
        @clear="clearSearchKey"
        placeholder="输入箱号/瓶号"
        show-action
        v-model="searchKey"
      >
        <div slot="action" @click="onSearch">搜索</div>
      </van-search>
    </div>

    <div class="checkboxs flex-column" v-if="type == 'prepare'">
      <div class="code-item" v-for="(item, index) in orderCodes" :key="index">
        <input
          type="checkbox"
          v-model="checkedIds"
          :value="item.id"
          @change="onChangeCodeSelect(item)"
        />
        <span>&nbsp;箱号: {{ item.boxCode }}&nbsp;&nbsp;瓶号: {{ item.bottleCode || '-' }}</span>
      </div>
      <div class="load-more" v-if="page<pageTotal && pageTotal!=0" @click="onClickLoadMore">点击加载更多</div>
      <div class="no-more" v-if="!(page<pageTotal && pageTotal!=0) && pageTotal>0">没有更多了</div>
      <div class="no-more" v-if="isSearch && pageTotal<1">没有找到相关库存</div>
    </div>
    <div v-for="(itemPrepare, index) in deliveryCodes" :key="index">
      <div class="head flex-row space-between" v-if="type == 'deliver'">
        <div style="margin-right: 50px;">{{ itemPrepare.name }}</div>
        <div style="width: 200px;text-align: right;">库存总量: {{ itemPrepare.total }}</div>
      </div>
      <div class="d-cell" :title="item.id" v-for="(item, index) in itemPrepare.codes" :key="index">
        <div class="flex-column">
          <div>生产日期批号: {{ item.dateCode }}</div>
          <div>箱体物流码 : {{ item.boxCode }}</div>
          <div>瓶号: {{ item.bottleCode || ' - ' }}</div>
        </div>
      </div>
    </div>

    <div class="bottom flex-row" v-if="type == 'prepare'">
      <div class="b-statistics flex-center">
        <span>已选择</span>
        <span class="b-price">{{ selectedCodes.length }}</span>
        <span>件</span>
      </div>
      <div class="pay-button" @click="confirmDelivery">完成备货</div>
    </div>
  </div>
</template>

<script>
import baseMixin from "../mixins/base.js";
import VCell from "../components/v-cell.vue";
import { Tag } from "vant";
import { setTimeout } from "timers";
export default {
  name: "PrePareOrder",
  mixins: [baseMixin],
  components: {
    "v-cell": VCell
  },
  data() {
    return {
      result: [],
      orderCodes: [],
      orderInfo: {},
      deliveryCodes: [],
      pageTotal: -1,
      page: 1,
      total: 0,
      pageSize: 15,
      type: "prepare", // deliver
      searchKey: "",
      selectedCodes: [],
      checkedIds: [],
      isSearch: false
    };
  },
  async mounted() {
    if (this.$route.name == "DeliverOrder") {
      this.type = "deliver";
    }
    this.orderId = this.$route.query.id;
    this.pid = this.$route.query.pid;
    await this.getOrderInfo();
    if (this.type == "deliver") {
      await this.getDeliveryCodes();
    } else {
      await this.getOrderCodes();
    }
  },
  computed: {
    productDetail() {
      if (!this.orderInfo || !this.orderInfo.items) {
        return null;
      }

      const items = this.orderInfo.items;

      if (!this.pid) {
        return this.getProductDetailData(items[0]);
      }

      const finder = items.find(item => item.productId === this.pid);

      return this.getProductDetailData(finder);
    }
  },
  methods: {
    getProductDetailData(item) {
      return {
        img: item.images[0],
        name: item.name,
        unitText: item.packUnitType === "2" ? "箱" : "瓶"
      };
    },
    onClickLoadMore() {
      this.page += 1;
      this.getOrderCodes(true);
      this.isSearch = false;
    },
    // 获取库存
    async getOrderCodes() {
      let res = await this.$API.getOrderCodes(
        this.companyId,
        this.orderId,
        this.pid,
        this.page,
        this.pageSize,
        this.searchKey
      );

      this.total = res.data.pagination.total;
      if (this.total > 0) {
        this.pageTotal = Math.ceil(this.total / this.pageSize);
      } else {
        this.pageTotal = 0;
      }

      this.orderCodes = this.orderCodes.concat(res.data.items);

      this.checkedIds = [];
      let selected;

      this.orderCodes.forEach((item, idx) => {
        if (this.selectedCodes.length < 1) {
          this.checkedIds = [];
        }

        this.selectedCodes.forEach((code, index) => {
          if (code.key == item.id) {
            this.checkedIds.push(item.id);
          }
        });
      });
    },
    async getDeliveryCodes() {
      let res = await this.$API.getDeliveryCodes(this.companyId, this.orderId);
      this.deliveryCodes = res.data.items;
    },
    async getOrderInfo() {
      let res = await this.$API.getOrderInfo(this.orderId);
      this.orderInfo = res.data;
    },
    async confirmDelivery() {
      if (this.selectedCodes.length == 0) {
        return this.$toast.fail("请选择库存");
      }
      let codeIds = [];
      this.selectedCodes.forEach(item => codeIds.push(item.key));
      //console.log(codeIds);
      //return;
      let res = await this.$API.confirmDelivery(
        this.companyId,
        this.orderId,
        codeIds,
        this.pid
      );
      if (res.status == 200) {
        this.$toast.success("备货成功！");
        setTimeout(() => {
          this.$router.push('/order/info?id=' + this.orderId + '&is_seller=1')
        }, 500);
      }
    },
    onSearch() {
      this.orderCodes = [];
      this.page = 1;
      this.getOrderCodes();
      this.isSearch = true;
    },
    onClose(tag) {
      this.selectedCodes.forEach((code, index) => {
        if (code.key == tag.key) {
          this.selectedCodes.splice(index, 1);
        }
      });
      this.orderCodes.forEach((item, idx) => {
        if (item.id == tag.key) {
          this.checkedIds.find((cid, i) => {
            if (cid == item.id) {
              this.checkedIds.splice(i, 1, null);
            }
          });
        }
      });
    },
    onChangeCodeSelect(row) {
      const isChecked = this.checkedIds.find(item => item == row.id);
      if (isChecked) {
        this.selectedCodes.push({
          key: row.id,
          name: row.bottleCode ? row.bottleCode : row.boxCode
        });
      } else {
        this.selectedCodes.forEach((code, index) => {
          if (code.key == row.id) {
            this.selectedCodes.splice(index, 1);
          }
        });
      }
    },
    clearSearchKey() {
      this.orderCodes = [];
      this.page = 1;
      this.getOrderCodes();
      this.isSearch = false;
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.head {
  padding: 10px 16px;
  box-sizing: border-box;
}
.codes-box {
  background-color: #fff;
  padding: 10px 16px 0 16px;
  .tag-box {
    margin-top: 4px;
  }

  .selected-box {
    margin-top: 6px;
    padding: 5px 10px;
    overflow-y: auto;
  }
  .selected-code-box {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 24px;

    color: #666;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid #ebedf0;
  }
}
.checkboxs {
  padding: 0px 16px 10px 16px;
  background: #fff;
  box-sizing: border-box;
  height: 68%;
  overflow-y: auto;
  .code-item {
    margin: 2px 0;
  }
  .van-checkbox {
    margin-bottom: 10px;
    // border-bottom: 1px solid #eee;
  }
}

.bottom {
  height: 50px;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background: #fff;
  border-top: 1px solid #ececec;

  .pay-button {
    height: 50px;
    width: 150px;
    color: #fff;
    font-size: 16px;
    line-height: 50px;
    text-align: center;
    // position: absolute;
    // right: 0;
    // bottom: 0;
    background: #ee6f2d;
  }

  .b-statistics {
    flex: 1;
    text-align: right;
    padding: 0 5px;
    justify-content: flex-end;
    font-size: 12px;

    .b-price {
      color: #ee6f2d;
      font-size: 16px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.d-cell {
  padding: 10px 16px;
  box-sizing: border-box;
  background: #fff;
}
.load-more {
  font-size: 14px;
  background: #fff;
  border: 1px solid #ececec;
  margin-bottom: 60px;
  text-align: center;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 7px;
  margin-top: 10px;
  line-height: 20px;
  color: #6b6b6b;
  &:active {
    background: #fff - 20%;
  }
}
.no-more {
  font-size: 14px;
  background: #fff;
  text-align: center;
  margin: 30px 0 70px;
  border-radius: 2px;
  box-sizing: border-box;
  color: #6b6b6b;
  &:active {
    background: #fff - 20%;
  }
}
</style>
