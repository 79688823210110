<template>
    <div>
        <van-nav-bar title="账号" />
        <div class="admin-header">
            <van-image 
                width="80"
                height="80"
                :src="companyInfo && companyInfo.logo || ''"
            >
                <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                </template>
            </van-image>
            <div>
                <div class="header-title">{{ companyInfo && companyInfo.name || '' }}</div>
                <div class="header-user">
                    {{`${userInfo && userInfo.name || ''} ${userInfo && userInfo.mobile || ''}`}}
                </div>
            </div>
        </div>
        <div>
            <van-cell 
                v-if="companyLocation && companyLocation.address"
                :title="companyLocation.address"
                icon="location-o"
                size="large"
            >
                <template #right-icon>
                    <van-button
                        size="mini" 
                        type="default"
                        @click="handleModifyLocation"
                    >修改</van-button>
                </template>
            </van-cell>
            <van-cell 
                v-else
                title="店铺地址未定位"
                icon="location-o"
                size="large"
                is-link 
                arrow-direction="down"
                :to="`admin/map-location`"
            />
        </div>
        <van-cell 
            title="个人基础信息"
            size="large"
            is-link 
            arrow-direction="down"
            :to="`admin/user-info?userName=${userInfo && userInfo.name || ''}&userMobile=${userInfo && userInfo.mobile || ''}`"
        />
        <van-cell 
            title="修改密码"
            size="large"
            is-link 
            arrow-direction="down"
            :to="`admin/modify-password?companyId=${companyId}&memberId=${memberId}`"
        />
        <van-cell 
            title="我的分享订单"
            size="large"
            is-link 
            arrow-direction="down"
            :to="`admin/shared-orders?companyId=${companyId}&memberId=${memberId}`"
        />
    </div>
</template>
<script>
import { getCurrentPosition, wgs84togcj02 } from "../common/location";

export default {
    name: 'Admin',
    data(){
        return {
            memberId: '',
            companyId: '',
            companyInfo: null,
            userInfo: null,
            companyLocation: null,
        }
    },
    async mounted(){
        this.memberId = this.$getters["user/userInfo"].userId;
        this.companyId = this.$getters["user/companies"][0].id;

        this.userInfo = await this.$API.getUserInfo(this.companyId, this.memberId)
        this.companyInfo = await this.$API.getCompanyInfo()

        this.getCompanyInfo();
        this.getCurrentLocation();
    },
    methods: {
        getCurrentLocation() {
            const { lng, lat, address } = this.$route.query;

            if (lng && lat && address) {
                const addr = window.decodeURI(address);

                this.getLocationInfo(lng, lat, addr);
            }
        },
        async handleModifyLocation() {
            const { longitude, latitude } = this.companyLocation;

            this.$router.replace(`admin/map-location?lng=${longitude}&lat=${latitude}`)
        },
        async getCompanyInfo() {
            const companyLocation = await this.$API.getCompanyLocation(this.companyId)

            if (companyLocation && companyLocation.items && companyLocation.items.address) {
                this.companyLocation = companyLocation.items; 
            }
        },
        async getLocationInfo(lng, lat, address) {
            const rst = await this.$API.getLocation(this.companyId, lng, lat, address);

            if (rst && rst.result) {
                this.companyLocation = {
                    latitude: lat, 
                    longitude: lng,
                    address,
                }; 
                
                // 清空query，防止二次重复绑定
                this.$router.push(`/admin`);
            } else {
                this.$notify('定位设置失败');
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .admin-header {
        display: flex;
        align-items: center;
        padding: 20px;
        
        .van-image {
            margin-right: 20px;
        }
    }

    .header-title {
        font-size: 24px;
        font-weight: bolder;
    }

    .header-user {
        margin-top: 10px;
        color: #999;
        font-size: 16px;
    }
</style>
