<template>
<div>
    <van-nav-bar title="确认订单" left-text="返回" @click-left="$router.back()" left-arrow fixed placeholder />
    <div class="product" v-if="goodsInfo.length">
        <div class="cell" v-if="defaultAddressId != -1" @click="goAddress">
            <van-cell is-link>
                <template slot="title">
                    <div class="flex-column">
                        <div class="flex-row">
                            <span class="addr-name">{{defaultAddress.name}}</span>
                            <span class="addr-phone">{{defaultAddress.mobile||defaultAddress.tel}}</span>
                        </div>
                        <span>{{detailAddress||defaultAddress.address}}</span>
                    </div>
                </template>
            </van-cell>
        </div>
        <div class="cell" v-if="defaultAddressId == -1">
            <van-cell title="请选择收货地址" is-link @click="goAddress"></van-cell>
        </div>
        <div class="price-region" v-if="!isSupply">
            {{`当前商品价格遵循 ${regionNames[$route.query.provinceId]}/${regionNames[$route.query.cityId]}/${regionNames[$route.query.districtId]} 共享价`}}
        </div>
        <div
            v-for="(item, idx) in goodsInfo"
            :key="idx"
            class="c-cell"
        >
            <div v-if="item.shop" class="company flex-center">
                <van-icon class="icon" name="shop-o" />
                <span>{{item.shop.name}}</span>
            </div>
            <div class="info flex-row">
                <img :src="item.imgs[0]" />
                <span>{{item.name}}</span>
            </div>
            <div class="statistics" >
                共 {{item.num}} 件商品
                <template v-if="type !=='supply'">
                    实付款:
                    <span class="price">￥{{stringMoney((item.price||item.officialPrice) * item.num)}}</span>
                </template>
            </div>
        </div>
    </div>
    <div class="remark">
        <van-cell-group>
            <van-field label="备注" v-model="remark" placeholder="填写备注" />
        </van-cell-group>
    </div>    
    <div class="bottom flex-row">
        <div class="b-statistics flex-center">
            <span>共 {{num}} 件商品 实付款:</span>
            <span class="b-price">￥{{totalPrice}}</span>
        </div>
        <div class="pay-button" @click="createTrade">确认下单</div>
    </div>
</div>
</template>

<script>
import { CodeToText } from 'element-china-area-data';
import baseMixin from '../mixins/base.js';
import AreaList from '../common/area.js';

export default {
    name: 'orderConfirm',
    components: {},
    mixins: [baseMixin],
    data() {
        return {
            regionNames: CodeToText,
            count: 1,
            imgs: [],
            shippingaddressesList: [],
            defaultAddressId: -1,
            defaultAddress: {},
            num: 0,
            buyCompanyId: -1,
            productId: [],
            goodsInfo: [],
            type: '',
            destination: {
                cityId: 510000,
                provinceId: 510100,
                districtId: 510104
            },
            pageType: 'single',
            buyCompanyId: -1,
            remark: ''
        }
    },
    computed: {
        isSupply() {
            return this.type === 'supply';
        },
        detailAddress() {
            if (!this.defaultAddress.districtId) return ''
            const pid = this.defaultAddress.districtId.substring(0, 2) + '0000'
            const cid = this.defaultAddress.districtId.substring(0, 4) + '00'
            const zid = this.defaultAddress.districtId
            return `${AreaList.province_list[pid]}/${AreaList.city_list[cid]}/${AreaList.county_list[zid]}/${this.defaultAddress.address}`
        },
        totalPrice() {
            if (this.isSupply) {
                return '0';
            }
            let price = 0;
            this.goodsInfo.forEach(_item => {
                price += (_item.price|| Number(_item.officialPrice)) * _item.num;
            });
            return this.stringMoney(price);
        },
    },
    mounted() {
        if (this.$route.name == 'ConfirmMuilti') {
            this.pageType = 'muilti'
        }
        this.destination = {
            cityId: this.$route.query.cityId,
            provinceId: this.$route.query.provinceId,
            districtId: this.$route.query.districtId
        };

        if (this.pageType == 'single') {
            this.productId.push({
                id: this.$route.query.id || -1,
                count: this.$route.query.num
            })
        } else {
            let orders = this.$getters['cart/tempOrders']
            orders.forEach(item=> {
                this.productId.push({
                    id: item.id,
                    count: item.count
                })
            })
        }   

        this.type = this.$route.query.type
        this.num = this.$route.query.num || 0
        this.buyCompanyId = this.$route.query.companyid
        this.requestData()
    },
    
    methods: {
        goAddress(){
            this.$store.commit('user/SET_CURRENT_PAGE', this.$route.fullPath)
            this.$router.push('/address')
        },
        async getShippingaddresses() {
            if (!!this.$getters["address/currentAddress"].name) {
                let address = this.$getters["address/currentAddress"]
                this.defaultAddress = address
                return this.defaultAddressId = address.id
            }
            let res = await this.$API.getShippingaddresses(this.companyId)
            this.shippingaddressesList = res.data.items
            for (let i = 0; i < this.shippingaddressesList.length; i++) {
                const address = this.shippingaddressesList[i];
                if (address.isDefault == '1') {
                    this.defaultAddressId = address.id
                    this.defaultAddress = address
                }
            }
        },
        async createTrade(){
            if (!this.defaultAddress.id) {
                return this.$toast.fail('请选择收货地址！');
            }
            if (!this.isSupply && this.defaultAddress.districtId !== this.$route.query.districtId) {
                return this.$toast.fail('收货地址区域 与 商品的共享价区域 不一致, 请统一区域后再下单。');
            }
            var items = []
            for (let i = 0; i < this.goodsInfo.length; i++) {
                const item = this.goodsInfo[i];
                items.push({
                    productId: item.id,
                    count: Number(item.num)
                })
            }
            let res = await this.$API.createTrade({
                items: items,
                // addressId: this.defaultAddress.id,
                name: this.defaultAddress.name,
                provinceId: this.defaultAddress.provinceId,
                cityId: this.defaultAddress.cityId,
                districtId: this.defaultAddress.districtId,
                address: this.defaultAddress.address,
                alias: this.defaultAddress.alias,
                zipcode: this.defaultAddress.zipcode,
                mobile: this.defaultAddress.mobile,
                buyerCompanyId: this.companyId,
                sellerCompanyId: this.type === 'supply' ? this.buyCompanyId : undefined,
                type: this.type,
                sellArea: this.type === 'shared' ? this.destination.districtId : undefined,
                remark: this.remark
            })

            if (res.status == 200) {
                if (this.pageType == 'muilti') {
                    this.$store.dispatch('cart/deleteMultipleProduct').then(() => {
                        this.$store.dispatch('cart/saveCart');
                    });
                }

                this.$toast.success('订单创建成功！')
                let destination = this.destination
                setTimeout(() => {
                    this.$router.replace(`/order/info?id=${res.data.orderId}`)
                }, 1000)
            }
        },
        async requestData() {
            const loading = this.$toast.loading({
                mask: true,
                message: '加载中...'
            });
            await this.getShippingaddresses()
            this.productId.forEach(async (item)=>{
                let res
                if (this.type === 'shared') {
                    res = await this.$API.getSharedProductInfo(item.id, this.type, this.destination)
                } else {
                    res = await this.$API.getBuyerProductInfo(this.buyCompanyId, item.id, this.type)
                }
                this.goodsInfo.push({
                    ...res.data,
                    imgs: res.data.images,
                    num: item.count
                });
                if (this.pageType === 'muilti') {
                    this.num += item.count;
                }
            });

            this.$forceUpdate()
            loading.clear()
        },
    }
}
</script>

<style lang="less" scoped>
.cell {
    margin-bottom: 5px;
}

.product {
    padding-bottom: 5px;
}

.bottom {
    height: 50px;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    background: #fff;
    border-top: 1px solid #ececec;

    .pay-button {
        height: 50px;
        width: 150px;
        color: #fff;
        font-size: 16px;
        line-height: 50px;
        text-align: center;
        // position: absolute;
        // right: 0;
        // bottom: 0;
        background: #ee6f2d;
    }

    .b-statistics {
        flex: 1;
        text-align: right;
        padding: 0 5px;
        justify-content: flex-end;
        font-size: 12px;

        .b-price {
            color: #ee6f2d;
            font-size: 16px;
            margin-left: 5px;
        }
    }

}

.addr-name {
    font-size: 16px;
    margin-right: 10px;
}

.c-cell {
    padding: 10px 0;
    box-sizing: border-box;
    margin-bottom: 5px;
    background: #fff;
}

.company {
    padding: 0 16px;
}

.info {
    margin-top: 10px;
    background: #fafafa;
    padding: 10px;
    margin-bottom: 10px;

    img {
        width: 5rem;
        height: 5rem;
        margin-right: 20px;
        border: 1px solid #ececec;
    }

    span {
        font-size: 16px;
    }
}

.statistics {
    padding: 0 16px;
    text-align: right;

    .price {
        font-size: 16px;
        margin-left: 10px;
    }
}

.price-region {
    background-color: #f2f2f2;
    padding: 10px 20px;
}
</style>
