<template>
<div class="home">
    <van-sticky>
        <div class="nav-header">
            <van-dropdown-menu>
                <van-dropdown-item @change="dropDownChange" v-model="type" :options="optionOrderType" />
            </van-dropdown-menu>
            <!-- <van-button class="search-icon" plain icon="search" round size="mini" @click="showSearch = !showSearch"></van-button> -->
        </div>
        <!-- <div  class="flex-row flex-center"> -->
        <van-search placeholder="请输入搜索关键词" shape="round" show-action v-model="searchKey">
            <div slot="action" @click="onSearch">搜索</div>
        </van-search>
        <!-- </div> -->

        <van-cell title="收货地" icon="location" is-link @click="showAreaSelect = true" :value="`${(id2name(destination.provinceId) || '省')} ${(id2name(destination.cityId) || '市')} ${(id2name(destination.districtId)|| '区')}` " size="mini" />
    </van-sticky>
    <div class="goods flex-row space-between">
        <div class="item" v-if="catalogList.length" v-for="(item,idx) in catalogList" :index="idx" :key="idx" @click="goDetail(item)">
            <div class="cover">
                <img :src="item.images[0]" />
                <div class="container" v-if="item.type=='supply'">
                    <div class="corner"><span>供</span></div>
                </div>
            </div>
            <div class="flex-column bottom">
                <div class="price" :style="item.type == 'shared' ? '' : 'color: #aaa;font-size:14px;'">{{item.type == 'shared' ? '￥'+stringMoney(item.price) : '零售价:' + stringMoney(item.officialPrice)}}</div>
                <div class="name">{{item.name}}</div>
                <!-- <div class="company flex-center">
                    <van-icon class="icon" name="shop-o" />
                    <span>{{item.shop.name}}</span>
                </div> -->
            </div>
        </div>

    </div>
    <div class="load-more" v-if="pageTotal === -1 || catalogList.length < pageTotal" @click="onClickLoadMore">
        加载更多
        <van-icon name="ellipsis" />
    </div>
    <div class="no-data" v-if="catalogList.length === 0">
        <van-icon name="info" />
        暂无数据
    </div>

    <div class="areaSelect">
        <transition name="van-fade">
            <van-overlay v-show="showAreaSelect" :show="showAreaSelect" @click="showAreaSelect = false"></van-overlay>
        </transition>
        <transition name="van-slide-up">
            <van-area @cancel="showAreaSelect = false" :value="''+destination.districtId" @confirm="confirmArea" v-show="showAreaSelect" class="area" :area-list="areaList" />
        </transition>
    </div>

</div>
</template>

<script>
import baseMixin from '../mixins/base.js'
import AreaList from '../common/area.js'
export default {
    name: 'Home',
    mixins: [baseMixin],
    data() {
        return {
            // showSearch: false,
            showAreaSelect: false,
            type: 'all',
            areaList: {},
            searchKey: '',
            destination: {
                cityId: 510000,
                provinceId: 510100,
                districtId: 510104
            },
            catalogList: [],
            page: 1,
            pageSize: 16,
            pageTotal: -1,
            isBusinessReadSharePrice: false
        }
    },
    computed: {
        optionOrderType() {
            const l = [{
                    text: '全部商品',
                    value: 'all'
                },
                {
                    text: '共享交易',
                    value: 'shared'
                },
                {
                    text: '供应商商品',
                    value: 'supply'
                },
            ]
            if (!this.isBusinessReadSharePrice) {
                l.splice(1, 1)
            }

            return l
        }
    },
    mounted() {
        this.requestData()
        this.areaList = AreaList
    },
    methods: {
        goDetail(info) {
            let destination = this.destination;
            this.$router.push({
                path: '/detail',
                query: {
                    id: info.productId,
                    companyid: info.shop && info.shop.id,
                    type: info.type,
                    cityId: destination.cityId,
                    provinceId: destination.provinceId,
                    districtId: destination.districtId,
                }
            });
        },
        confirmArea(e) {
            this.destination = {
                provinceId: e[0].code,
                cityId: e[1].code,
                districtId: e[2].code
            }
            this.$store.commit('user/SET_DEFAULT_AREA', this.destination)
            this.showAreaSelect = false
            this.catalogList = []
            this.page = 1
            this.requestData(true)
        },
        dropDownChange(e) {
            this.type = e
            this.catalogList = []
            this.page = 1
            this.requestData(true)
        },
        // 获取用户默认区域
        async getCompanyArea() {
            if (this.$getters['user/defaultArea']) {
                return this.destination = this.$getters['user/defaultArea']
            }
            let res = await this.$API.getCompanyArea(this.companyId)
            if (res.status == 200) {
                this.destination = res.data.items[0]
            }
        },
        async requestData(isRefresh) {
            const loading = this.$toast.loading({
                mask: true,
                message: '加载中...'
            });
            this.isBusinessReadSharePrice = await this.getPermissionShared()
            if (!isRefresh) {
                await this.getCompanyArea()
            }
            let res = await this.$API.getCatalogs(this.companyId, {
                page: this.page,
                pageSize: this.pageSize,
            }, {
                provinceId: this.destination.provinceId,
                cityId: this.destination.cityId,
                districtId: this.destination.districtId,
                brandId: undefined,
                type: this.isBusinessReadSharePrice ? (this.type == 'all' ? null : this.type) : 'supply',
                searchKey: this.searchKey
            })
            this.pageTotal = res.data.pagination.total
            this.catalogList = this.catalogList.concat(res.data.items)
            loading.clear()
        },
        onClickLoadMore() {
            this.page += 1
            this.requestData(true)
        },
        onSearch() {
            this.catalogList = []
            this.page = 1
            this.requestData(true)
        },
        async getPermissionShared() {
            let res = await this.$API.getCompaniesPermissionList(this.companyId)
            let permissions = res.data.items
            for (let i = 0; i < permissions.length; i++) {
                const permission = permissions[i];
                if (permission == 'BusinessReadSharePrice') {
                    return true
                }
            }
            return false
        },
    }
}
</script>

<style lang="less" scoped>
.nav-header {
    height: 50px;
    position: relative;
    box-sizing: border-box;

    .search-icon {
        position: absolute;
        top: 15px;
        right: 20px;
    }
}

.goods {
    padding: 10px;
    box-sizing: border-box;
    flex-wrap: wrap;

    .item {
        width: 48%;
        border: 1px solid #e4e4e4;
        margin-bottom: 20px;
        background: #fff;

        .cover {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            position: relative;
            border-bottom: 1px solid #e4e4e4;

            img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                padding: 20px;
                box-sizing: border-box;
            }

            .container {
                position: relative;

                .corner {
                    width: 0;
                    height: 0;
                    border-top: 45px solid #fc6620;
                    border-bottom: 45px solid transparent;
                    border-left: 45px solid transparent;
                    position: absolute;
                    right: 0;
                }

                .corner span {
                    position: absolute;
                    top: -42px;
                    left: -20px;
                    text-align: center;
                    font-size: 16px;
                    font-family: arial;
                    color: white;
                    display: block;
                }
            }
        }

        &:ntr-child(1n+0) {
            margin-right: 2%;
        }

        .bottom {
            padding: 10px;
            box-sizing: border-box;
        }

        .price {
            height: 30px;
            font-size: 18px;
            color: red;
            line-height: 30px;
        }

        .name {
            font-size: 13px;
            height: 36px;
            margin-bottom: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .company {
            font-size: 12px;

            .icon {
                margin-right: 5px;
            }
        }
    }
}

.no-data {
    width: 100%;
    font-size: 18px;
    margin-top: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #6b6b6b;

    .van-icon {
        font-size: 40px;
        margin-bottom: 20px;
    }
}

.load-more {
    font-size: 14px;
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    border: 1px solid #ececec;
    margin: 0 10px;
    margin-bottom: 40px;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 10px;
    color: #6b6b6b;

    &:active {
        background: #fff - 20%;
    }
}

.areaSelect {
    width: 100%;
    position: fixed;
    bottom: 50px;
    left: 0;

    .area {
        z-index: 2001;
    }
}

.van-cell__title {
    flex: initial;
}

.van-cell__value {
    flex: 1;
}
</style>
