const state = {
    isLogin: false,
    session: '',
    error_count: 0,
}

const getters = {
    loginState: (state, getters) => {
        return state.isLogin
    },
    loginSession: (state, getters) => {
        return state.session
    },
    errorCount: (state, getters) => {
        return state.error_count
    }
}

// mutations
const mutations = {
    SET_LOGIN_STATE(state, status) {
        console.log('SET_LOGIN_STATE')
        state.isLogin = status
    },

    // 登录失败次数
    SET_LOGIN_ERROR_COUNT(state) {
        console.log('SET_LOGIN_ERROR_COUNT')
        state.error_count++
    },

    CLEAR_LOGIN_ERROR_COUNT(state) {
        state.error_count = 0
    },

    CLEAR_LOGIN_STATE(state) {
        state.isLogin = false
        state.session = null
    },

    SET_LOGIN_SESSION(state, status) {
        state.session = status
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}