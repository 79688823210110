
module.exports = function (res) {
    if (res.response) {
        if (res.response.data.success === false) {
            return res.response.data.errorMessage
        }
        
        if (res.response.data.message) {
            return res.response.data.message
        }
        
    }
    return '未知错误'
}