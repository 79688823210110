const axios = require('axios')
axios.defaults.headers.post['Content-Type'] = 'application/json';
import store from '../store.js' 
import AttachError from './attachError'
import _config from '../config'
import { Notify } from 'vant';
import router from '../router'

// http request 拦截器
axios.interceptors.request.use(
    config => {
        // 
        if (!/^((ht|f)tps?):\/\/([\w\-]+(\.[\w\-]+)*\/)*[\w\-]+(\.[\w\-]+)*\/?(\?([\w\-\.,@?^=%&:\/~\+#]*)+)?/.test(config.url) && config.url != '/config.json') {
            // config.url = _config.prefix + config.url
            config.url = window.PREFIX + config.url
        }
        if (store.getters['login/loginSession']) { 
            const excludes = [
                /\/business\/api\/login/,    
                /\/business\/api\/wx\/login/,
                /\/business\/api\/wx\/bindopenid/,
                /\/business\/api\/wx\/getopenid/,
                /\/business\/api\/wx\/getappid/,
            ]
            for (const p of excludes) {
                if (p.test(config.url)) {
                    return config;
                }
            }
            config.headers.Authorization = store.getters['login/loginSession'];
        }
        return config;
    },
    err => {
        return err
    });

// http response 拦截器
axios.interceptors.response.use(
    response => {
        if (response.headers['bjt-auth-token']) {
            store.commit('login/SET_LOGIN_SESSION', response.headers['bjt-auth-token'])
        }
        return {
            status: response.status,
            error: false,
            data: response.data,
            error_msg: '',
        }
    },
    error => {
        console.log(error.response)
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    // 返回 401 清除token信息并跳转到登录页面
                    store.commit('login/CLEAR_LOGIN_STATE');
                    router.push('/login')
                    return
            }
        }
        console.log(AttachError(error))
        const excludes = [
            /\/business\/api\/wx\/login/,
            /\/business\/api\/wx\/bindopenid/,
            /\/business\/api\/wx\/getopenid/,
            /\/business\/api\/wx\/getappid/,
        ]
        for (const p of excludes) {
            if (p.test(error.response.config.url)){
                return {
                    status: error.response ? error.response.status : -1,
                    error: true,
                    data: error.response ? error.response : null,
                    error_msg: AttachError(error)
                }
            }
        }
        if (error.response.config.url != '/config.json') {
            Notify(AttachError(error));
        }
        
        return {
            status: error.response ? error.response.status : -1,
            error: true,
            data: error.response ? error.response : null,
            error_msg: AttachError(error)
        }
        // return Promise.reject(error.response.data);   // 返回接口返回的错误信息
    });

export default axios