<template>
    <div>
        <van-nav-bar title="修改密码" left-text="返回" @click-left="$router.back()" left-arrow />
        <van-cell-group>
            <van-field v-model.trim="oldPassword" type="password" label="原密码" required label-width="120"/>
            <van-field v-model.trim="newPassword" type="password" label="输入新密码" required label-width="120"/>
            <van-field v-model.trim="repeatPassword" type="password" label="再次输入新密码" required label-width="120"/>
        </van-cell-group>
        <van-button class="submit-btn" type="warning" @click="submit" :disabled="disabled" :loading="loading">确定</van-button>
    </div>
</template>
<script>
export default {
    name: 'ModifyPassword',
    data(){
        return {
            companyId: this.$route.query.companyId,
            memberId: this.$route.query.memberId,
            oldPassword: '',
            newPassword: '',
            repeatPassword: '',
            loading: false,
        }
    },
    computed: {
        disabled() {
            return !this.oldPassword || !this.newPassword || !this.repeatPassword
        }
    },
    methods: {
        async submit() {
            if (this.oldPassword === this.newPassword) {
                return this.$notify('原密码和新密码一致');
            }
            if (this.newPassword !== this.repeatPassword) {
                return this.$notify('两次新密码不一致');
            }

            this.loading = true

            let res = await this.$API.updatePwd(this.companyId, this.memberId, {
                oldPassword: this.oldPassword,
                newPassword: this.newPassword,
                repeatPassword: this.repeatPassword,
            });

            if (res.status == 200) {
                this.$notify({
                    message: '密码修改成功, 请重新登录',
                    background: '#20c064',
                    duration: 2000,
                    onClose: () => {
                        this.$router.push('/login')
                    }
                })
            } else {
                this.loading = false
            }
        }
    },
}
</script>

<style lang="less" scoped>
.submit-btn {
    margin-top: 20px;
    width: 100%;
}
</style>
