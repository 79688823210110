import axios from '../common/axios'
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
    async getCatalogs(companyId,pagion = { page: 1, pageSize: 20 },query){
        return axios.get(`/business/api/companies/${companyId}/catalog`,{
            params: {
                page: pagion.page,
                pageSize: pagion.pageSize,
                ...query
            }
        })
    }
}
// /business/api/companies/{companyId}/catalog