<template>
<div>
    <van-nav-bar :title="tradeRole == 'buyer' ? '采购订单' : '供应订单'">
    </van-nav-bar>
    <van-tabs v-model="active" animated @change="tabChange" sticky>
        <van-tab>
            <div slot="title" class="flex-row flex-center just-center">
                <span class="tab-title">待匹配</span>
                <van-tag plain type="danger" v-if="infoPendingMatchSupplier.pagination">{{infoPendingMatchSupplier.pagination ? infoPendingMatchSupplier.pagination.total: 0}}</van-tag>
            </div>
            <van-list
                v-model="loadingPendingMatchSupplier"
                :finished="finishedPendingMatchSupplier"
                finished-text="没有更多了"
                @load="pageLoad"
            >
                <div class="c-cell" @click="orderInfo(item.id)" v-for="(item,idx) in infoPendingMatchSupplier.items" :index="idx" :key="idx">
                    <div class="company flex-row space-between">
                        <div class="flex-center">
                            <template v-if="item.seller">
                                <van-icon class="icon" name="shop-o" />
                                <span>{{item.seller.name}}</span>
                            </template>
                        </div>
                        <div>{{item.status.name}}</div>
                    </div>
                    <div class="info flex-row">
                        <img v-if="item.items[0].images" :src="item.items[0].images[0]" />
                        <div class="flex-column space-between">
                            <span>{{item.items[0].name}}</span>
                            <span :class="'price-shared'">￥{{stringMoney(item.items[0].price)}}</span>
                        </div>
                        
                    </div>
                    <div class="statistics">
                        共 {{ reduceCount(item.items) }} 件商品 实付款:￥
                        <span class="price">{{countPrice(item.items)}}</span>
                    </div>
                </div>
            </van-list>
        </van-tab>
        <van-tab>
            <div slot="title" class="flex-row flex-center just-center">
                <span class="tab-title">待接单</span>
                <van-tag plain type="danger" v-if="infoPendingAccept.pagination">{{infoPendingAccept.pagination ? infoPendingAccept.pagination.total: 0}}</van-tag>
            </div>
            <van-list
                v-model="loadingPendingAccept"
                :finished="finishedPendingAccept"
                finished-text="没有更多了"
                @load="pageLoad"
            >
                <div class="c-cell" @click="orderInfo(item.id)" v-for="(item,idx) in infoPendingAccept.items" :index="idx" :key="idx">
                    <div class="company flex-row space-between">
                        <div class="flex-center">
                            <van-icon class="icon" name="shop-o" />
                            <span>{{item.seller.name}}</span>
                        </div>
                        <div>{{item.status.name}}</div>
                    </div>
                    <div class="info flex-row">
                        <img v-if="item.items[0].images" :src="item.items[0].images[0]" />
                        <div class="flex-column space-between">
                            <span>{{item.items[0].name}}</span>
                            <span :class="'price-shared'">￥{{stringMoney(item.items[0].price)}}</span>
                        </div>
                        
                    </div>
                    <div class="statistics">
                        共 {{ reduceCount(item.items) }} 件商品 实付款:￥
                        <span class="price">{{countPrice(item.items)}}</span>
                    </div>
                </div>
            </van-list>
        </van-tab>
        <van-tab>
            <div slot="title" class="flex-row flex-center just-center">
                <span class="tab-title">价格确认</span>
                <van-tag plain type="danger" v-if="infoChangePrice.pagination">{{infoChangePrice.pagination ? infoChangePrice.pagination.total: 0}}</van-tag>
            </div>
            <van-list v-model="loadingChangePrice" :finished="finishedChangePrice" finished-text="没有更多了" @load="pageLoad">
                <div class="c-cell" @click="orderInfo(item.id)" v-for="(item,idx) in infoChangePrice.items" :index="idx" :key="idx">
                    <div class="company flex-row space-between">
                        <div class="flex-center">
                            <van-icon class="icon" name="shop-o" />
                            <span>{{item.seller.name}}</span>
                        </div>
                        <div>{{item.status.name}}</div>
                    </div>
                    <div class="info flex-row">
                        <img v-if="item.items[0].images" :src="item.items[0].images[0]" />
                        <div class="flex-column space-between">
                            <span>{{item.items[0].name}}</span>
                            <span :class="'price-shared'">￥{{stringMoney(item.items[0].price)}}</span>
                        </div>
                        
                    </div>
                    <div class="statistics">
                        共 {{ reduceCount(item.items) }} 件商品 实付款:￥
                        <span class="price">{{countPrice(item.items)}}</span>
                    </div>
                </div>
            </van-list>


        </van-tab>
        <van-tab>
            <div slot="title" class="flex-row flex-center just-center">
                <span class="tab-title">备货中</span>
                <van-tag plain type="danger" v-if="infoDelivery.pagination">{{infoDelivery.pagination ? infoDelivery.pagination.total: 0}}</van-tag>
            </div>
            <van-list v-model="loadingDelivery" :finished="finishedDelivery" finished-text="没有更多了" @load="pageLoad">
                <div class="c-cell" @click="orderInfo(item.id)" v-for="(item,idx) in infoDelivery.items" :index="idx" :key="idx">
                    <div class="company flex-row space-between">
                        <div class="flex-center">
                            <van-icon class="icon" name="shop-o" />
                            <span>{{item.seller.name}}</span>
                        </div>
                        <div>{{item.status.name}}</div>
                    </div>
                    <div class="info flex-row">
                        <img v-if="item.items[0].images" :src="item.items[0].images[0]" />
                        <div class="flex-column space-between">
                            <span>{{item.items[0].name}}</span>
                            <span :class="'price-shared'">￥{{stringMoney(item.items[0].price)}}</span>
                        </div>
                    </div>
                    <div class="statistics">
                        共 {{ reduceCount(item.items) }} 件商品 实付款:￥
                        <span class="price">{{countPrice(item.items)}}</span>
                    </div>
                </div>
            </van-list>

        </van-tab>
        <van-tab>
            <div slot="title" class="flex-row flex-center just-center">
                <span class="tab-title">待支付</span>
                <van-tag plain type="danger" v-if="infoWaitingPay.pagination">{{infoWaitingPay.pagination ? infoWaitingPay.pagination.total: 0}}</van-tag>
            </div>
            <van-list v-model="loadingWaitingPay" :finished="finishedWaitingPay" finished-text="没有更多了" @load="pageLoad">
                <div class="c-cell" @click="orderInfo(item.id)" v-for="(item,idx) in infoWaitingPay.items" :index="idx" :key="idx">
                    <div class="company flex-row space-between">
                        <div class="flex-center">
                            <van-icon class="icon" name="shop-o" />
                            <span>{{item.buyer.name}}</span>
                        </div>
                        <div>{{item.status.name}}</div>
                    </div>
                    <div class="info flex-row">
                        <img v-if="item.items[0].images" :src="item.items[0].images[0]" />
                        <div class="flex-column space-between">
                            <span>{{item.items[0].name}}</span>
                            <span :class="'price-shared'">￥{{stringMoney(item.items[0].price)}}</span>
                        </div>
                    </div>
                    <div class="statistics">
                        共 {{ reduceCount(item.items) }} 件商品 实付款: ￥
                        <span class="price">{{countPrice(item.items)}}</span>
                    </div>
                </div>
            </van-list>

        </van-tab>
        <van-tab>
            <div slot="title" class="flex-row flex-center just-center">
                <span class="tab-title">配送中</span>
                <van-tag plain type="danger" v-if="infoDelivering.pagination">{{infoDelivering.pagination ? infoDelivering.pagination.total: 0}}</van-tag>
            </div>
            <van-list v-model="loadingDelivering" :finished="finishedDelivering" finished-text="没有更多了" @load="pageLoad">
                <div class="c-cell" @click="orderInfo(item.id)" v-for="(item,idx) in infoDelivering.items" :index="idx" :key="idx">
                    <div class="company flex-row space-between">
                        <div class="flex-center">
                            <van-icon class="icon" name="shop-o" />
                            <span>{{item.seller.name}}</span>
                        </div>
                        <div>{{item.status.name}}</div>
                    </div>
                    <div class="info flex-row">
                        <img v-if="item.items[0].images" :src="item.items[0].images[0]" />
                        <div class="flex-column space-between">
                            <span>{{item.items[0].name}}</span>
                            <span :class="'price-shared'">￥{{stringMoney(item.items[0].price)}}</span>
                        </div>
                    </div>
                    <div class="statistics">
                        共 {{ reduceCount(item.items) }} 件商品 实付款:￥
                        <span class="price">{{countPrice(item.items)}}</span>
                    </div>
                </div>
            </van-list>
        </van-tab>
        <van-tab>
            <div slot="title" class="flex-row flex-center just-center">
                <span class="tab-title">完成</span>
            </div>
            <van-list v-model="loadingCompleted" :finished="finishedCompleted" finished-text="没有更多了" @load="pageLoad">
                <div class="c-cell" @click="orderInfo(item.id)" v-for="(item,idx) in infoCompleted.items" :index="idx" :key="idx">
                    <div class="company flex-row space-between">
                        <div class="flex-center">
                            <van-icon class="icon" name="shop-o" />
                            <span>{{item.seller.name}}</span>
                        </div>
                        <div>{{item.status.name}}</div>
                    </div>
                    <div class="info flex-row">
                        <img v-if="item.items[0].images" :src="item.items[0].images[0]" />
                        <div class="flex-column space-between">
                            <span>{{item.items[0].name}}</span>
                            <span :class="'price-shared'">￥{{stringMoney(item.items[0].price)}}</span>
                        </div>
                    </div>
                    <div class="statistics">
                        共 {{ reduceCount(item.items) }} 件商品 实付款:￥
                        <span class="price">{{countPrice(item.items)}}</span>
                    </div>
                </div>
            </van-list>
        </van-tab>
    </van-tabs>

</div>
</template>

<script>
import baseMixin from '../mixins/base.js'
import { reduce } from "lodash"

export default {
    name: 'OrderList',
    mixins: [baseMixin],
    components: {},
    data() {
        return {
            active: 0,
            curStep: 'delivery',
            // 待确认价格 changePrice
            // 待备货 delivery
            // 配送中 delivering
            // 确认收货 completed

            tradeRole: 'buyer',
            // url中companyId在交易中的类型   采购 buyer  供应 seller
            // 待匹配
            loadingPendingMatchSupplier: false,
            pagePendingMatchSupplier: 1,
            finishedPendingMatchSupplier: false,
            infoPendingMatchSupplier: [],
            // 待接单
            loadingPendingAccept: false,
            pagePendingAccept: 1,
            finishedPendingAccept: false,
            infoPendingAccept: [],
            // 待确认价格 ChangePrice
            loadingChangePrice: false,
            pageChangePrice: 1,
            finishedChangePrice: false,
            infoChangePrice: [],
            // 待备货 delivery
            loadingDelivery: false,
            pageDelivery: 1,
            finishedDelivery: false,
            infoDelivery: [],
            // 未支付 waitingPay
            loadingWaitingPay: false,
            pageWaitingPay: 1,
            finishedWaitingPay: false,
            infoWaitingPay: [],
            // 配送中 delivering
            loadingDelivering: false,
            pageDelivering: 1,
            finishedDelivering: false,
            infoDelivering: [],
            // 确认收货 completed
            loadingCompleted: false,
            pageCompleted: 1,
            finishedCompleted: false,
            infoCompleted: [],
            firstLoad: true,
        }
    },
    mounted() {
        if (this.$route.name == 'OrderBuyer') {
            this.tradeRole = 'buyer'
        } else {
            this.tradeRole = 'seller'
        }
        this.active = this.$getters["tab/buyerState"]
        this.requestData()
    },
    methods: {
        reduceCount(items) {
            return reduce(items, function(rst, item) {
                return rst + item.num
            }, 0);
        },
        countPrice(items) {
            const price = items.reduce((pre, curr) => {
                return pre + (curr.price * curr.num);
            }, 0);
            return this.stringMoney(price);
        },
        orderInfo(id) {
            this.$router.push('/order/info?id=' + id)
        },
        tabChange(e) {
            this.$store.commit("tab/SET_BUYER_STATE", e);
        },
        async pageLoad() {
            console.log('pageload')
            if (this.firstLoad) {
                return  this.firstLoad = false
            }
            switch (this.active) {
                case 0:
                    this.curStep = 'pendingMatchSupplier'
                    this.pagePendingMatchSupplier++
                    this.loadingPendingMatchSupplier = true
                    break;
                case 1:
                    this.curStep = 'pendingAccept'
                    this.pagePendingAccept++
                    this.loadingPendingAccept = true
                    break;
                case 2:
                    this.curStep = 'acceptPrice'
                    this.pageChangePrice++
                    this.loadingChangePrice = true
                    break;
                case 3:
                    this.curStep = 'delivery'
                    this.pageDelivery++
                    this.loadingDelivery = true
                    break;
                case 4:
                    this.curStep = 'waitingPay'
                    this.pageWaitingPay++
                    this.loadingWaitingPay = true
                    break;
                case 5:
                    this.curStep = 'delivering'
                    this.pageDelivering++
                    this.loadingDelivering = true
                    break;
                case 6:
                    this.curStep = 'completed'
                    this.pageCompleted++
                    this.loadingCompleted = true
                    break;
            }
            await this.requestList(this.curStep, true)
        },
        async requestList(step, loadmore) {
            let loading = null
            if (loadmore) {
                loading = this.$toast.loading({
                    mask: true,
                    message: '加载中...'
                });
            }
            switch (step) {
                case 'pendingMatchSupplier':
                    this.page = this.pagePendingMatchSupplier
                    break;
                case 'pendingAccept':
                    this.page = this.pagePendingAccept
                    break;
                case 'acceptPrice':
                    this.page = this.pageChangePrice
                    break;
                case 'delivery':
                    this.page = this.pageDelivery
                    break;
                case 'waitingPay':
                    this.page = this.pageWaitingPay
                    break;
                case 'delivering':
                    this.page = this.pageDelivering
                    break;
                case 'completed':
                    this.page = this.pageCompleted
                    break;
            }
            let curStep = step
            let orderRes = await this.$API.getOrderList(this.companyId, {
                page: this.page,
                pageSize: this.pageSize,
            }, {
                tradeRole: this.tradeRole,
                curStep
            })
            if (orderRes.status == 200) {
                let product = orderRes.data
                switch (step) {
                    case 'pendingMatchSupplier':
                        if (loadmore && this.infoPendingMatchSupplier.items instanceof Array) {
                            this.infoPendingMatchSupplier.items = this.infoPendingMatchSupplier.items.concat(product.items)
                        } else {
                            this.infoPendingMatchSupplier = product
                        }
                        this.loadingPendingMatchSupplier = false
                        this.finishedPendingMatchSupplier = !orderRes.data.pagination ? true : ((orderRes.data.pagination.total / (this.pagePendingAccept * this.pageSize)) <= 1)
                        break;
                    case 'pendingAccept':
                        if (loadmore && this.infoPendingAccept.items instanceof Array) {
                            this.infoPendingAccept.items = this.infoPendingAccept.items.concat(product.items)
                        } else {
                            this.infoPendingAccept = product
                        }
                        this.loadingPendingAccept = false
                        this.finishedPendingAccept = !orderRes.data.pagination ? true : ((orderRes.data.pagination.total / (this.pagePendingAccept * this.pageSize)) <= 1)
                        break;
                    case 'acceptPrice':
                        if (loadmore && this.infoChangePrice.items instanceof Array) {
                            this.infoChangePrice.items = this.infoChangePrice.items.concat(product.items)
                        } else {
                            this.infoChangePrice = product
                        }
                        this.loadingChangePrice = false
                        this.finishedChangePrice = !orderRes.data.pagination ? true : ((orderRes.data.pagination.total / (this.pageChangePrice * this.pageSize)) <= 1)
                        break;
                    case 'delivery':
                        if (loadmore && this.infoDelivery.items instanceof Array) {
                            this.infoDelivery.items = this.infoDelivery.items.concat(product.items)
                        } else {
                            this.infoDelivery = product
                        }
                        this.loadingDelivery = false
                        this.finishedDelivery = !orderRes.data.pagination ? true : ((orderRes.data.pagination.total / (this.pageDelivery * this.pageSize)) <= 1)
                        break;
                    case 'waitingPay':
                        if (loadmore && this.infoWaitingPay.items instanceof Array) {
                            this.infoWaitingPay.items = this.infoWaitingPay.items.concat(product.items)
                        } else {
                            this.infoWaitingPay = product
                            
                        }
                        this.loadingWaitingPay = false
                        this.finishedWaitingPay = !orderRes.data.pagination ? true : ((orderRes.data.pagination.total / (this.pageWaitingPay * this.pageSize)) <= 1)
                    break;
                    case 'delivering':
                        if (loadmore && this.infoDelivering.items instanceof Array) {
                            this.infoDelivering.items = this.infoDelivering.items.concat(product.items)
                        } else {
                            this.infoDelivering = product
                        }
                        this.loadingDelivering = false
                        this.finishedDelivering = !orderRes.data.pagination ? true : ((orderRes.data.pagination.total / (this.pageDelivering * this.pageSize)) <= 1)
                        break;
                    case 'completed':
                        if (loadmore && this.infoCompleted.items instanceof Array) {
                            this.infoCompleted.items = this.infoCompleted.items.concat(product.items)
                        } else {
                            this.infoCompleted = product
                        }
                        this.loadingCompleted = false
                        this.finishedCompleted = !orderRes.data.pagination ? true : ((orderRes.data.pagination.total / (this.pageCompleted * this.pageSize)) <= 1)
                        break;
                }
            }
            if (loadmore) {
                loading.clear()
            }
        },
        async requestData() {
            const loading = this.$toast.loading({
                mask: true,
                message: '加载中...'
            });
            await this.requestList('pendingMatchSupplier');
            await this.requestList('pendingAccept');
            await this.requestList('acceptPrice')
            await this.requestList('delivery')
            await this.requestList('waitingPay')
            await this.requestList('delivering')
            await this.requestList('completed')
            loading.clear()
        },
    }
}
</script>

<style lang="less" scoped>
.c-cell {
    padding: 10px 0;
    box-sizing: border-box;
    margin-bottom: 5px;
    background: #fff;
    margin-top: 5px;
}

.company {
    padding: 0 16px;

    .icon {
        margin-right: 5px;
    }
}

.info {
    margin-top: 10px;
    background: #fafafa;
    padding: 10px;
    margin-bottom: 10px;

    img {
        width: 5rem;
        height: 5rem;
        margin-right: 20px;
        border: 1px solid #ececec;
    }

    span {
        font-size: 16px;
    }
}

.statistics {
    padding: 0 16px;
    text-align: right;

    .price {
        font-size: 16px;
        margin-left: 10px;
    }
}

.tab-title {
    margin-right: 4px;
}
</style>
