const state = {
    companies: {},
    userInfo: {},
    defaultArea: null
}

const getters = {
    userInfo: (state, getters) => {
        return state.userInfo
    },
    companies: (state, getters) => {
        return state.companies
    },
    defaultArea: (state,getters) =>{
        return state.defaultArea
    },
    currentPage: (state,getters) => {
        return state.currentPage
    },
}

// mutations
const mutations = {
    SET_COMPANIES(state, status) {
        state.companies = status
    },
    SET_USERINFO(state, status) {
        state.userInfo = status
    },
    SET_DEFAULT_AREA(state,status) {
        state.defaultArea = status
    },
    SET_CURRENT_PAGE(state,status) {
        state.currentPage = status
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}