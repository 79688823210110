import Vue from 'vue'
import Vuex from 'vuex'
import login from './store/modules/login'
import user from './store/modules/user'
import address from './store/modules/address'
import tab from './store/modules/tab'
import cart from './store/modules/cart'
import createPersistedState from "vuex-persistedstate"


Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.localStorage
    })],
    modules: {
        login,
        user,
        address,
        tab,
        cart
    }
})