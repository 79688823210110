<template>
    <div></div>
</template>
<script>
export default {
    name: 'Blank',
    data(){
        return {

        }
    },
    created(options){
        this.$router.push(`${this.$route.query.path}`)
    }
}
</script>


